import { createStore, compose, applyMiddleware } from 'redux';
// React redux firebase implementation was migrated to v3
// http://react-redux-firebase.com/docs/v3-migration-guide.html
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import rootReducer from './reducers';

const middlewares = [
  thunk,
  process.env.REACT_APP_LOGGER === 'on' && logger
].filter(Boolean);

// Create store with reducers and initial state
const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middlewares))
);

export default store;
