export const COMPANY = 'company';
export const CUSTOMER_CREATED = 'customerCreated';
export const CUSTOMER_UPDATED = 'customerUpdated';
export const ERROR_SAVING_CUSTOMER = 'An error occurred trying to save the customer.';
export const ERROR_SAVING_COMPANY = 'An error occurred trying to save the company.';
export const ERROR_EMAIL_ALREADY_REGISTERED_BY_USER = 'There is a customer already registered with this email.';
export const ERROR_EMAIL_ALREADY_REGISTERED_BY_COMPANY = 'There is a company already registered with this email.';
export const SUCCESS_CUSTOMER_CREATED = 'Customer created successfully!';
export const SUCCESS_CUSTOMER_UPDATED = 'Customer updated successfully!';
export const ERROR_APPROVING_CUSTOMER = 'An error occurred trying to approve the customer.';
export const ERROR_SELECT_A_COMPANY = 'Please, select or create a company.';
export const ERROR_RECIPIENT_EMAIL_IN_USE = 'There is an existing recipient using this email.';
