import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Loading extends Component {
  static propTypes = {
    message: PropTypes.string
  };

  render() {
    const { message } = this.props;

    return (
      <div className="full-height flex-column d-flex justify-content-center align-items-center">
        <div className="progress-circle-indeterminate"></div>
        {message || 'Loading'}
      </div>
    )
  }
}

export default Loading;
