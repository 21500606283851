const firebase = {
  apiKey: 'AIzaSyAzMCmzA871PGupWiubC2zehUvsIJrstZ0',
  authDomain: 'boltbox-admin-dev.firebaseapp.com',
  databaseURL: 'https://boltbox-admin-dev.firebaseio.com',
  projectId: 'boltbox-admin-dev',
  storageBucket: 'boltbox-admin-dev.appspot.com',
  messagingSenderId: '632535638883'
};

const google = {
  analytics: {
    trackingId: '',
  },
  recaptcha: {
    siteKey: '6Ld_kPwUAAAAABec0v2gXCGtnkisX6bNc_-fDhb5',
  }
};

const sentry = {
  dsn: 'https://468a99381da14fdf88f8e92e445bd761@sentry.io/1806104',
  release: 'boltbox-admin@ef138eb17228443440382ce1d150501bb81af497'
};

const domain = 'boltbox-admin-dev.firebase.com';
const site = {
  domain,
  title: 'BoltBox App Admin',
  url: `https://${domain}`,
  development: true,
};

const mapbox = {
  accessToken: 'pk.eyJ1IjoiYm9sdGJveGFwcCIsImEiOiJjazJqNzhib2cxb2VkM2R0ZzNydGZsNHBvIn0.VbHfTsBzW18d8jjl3qFyHw',
  navigationApi: 'https://api.mapbox.com/directions/v5/mapbox/driving/{location}?access_token={accessToken}',
  showInstructions: true,
};

// Our own apis
const api = {
  searchPlaces: 'https://us-central1-boltbox-admin-dev.cloudfunctions.net/searchPlace',
  geocode: 'https://us-central1-boltbox-admin-dev.cloudfunctions.net/geocode',
  sendSMS: 'https://us-central1-boltbox-admin-dev.cloudfunctions.net/sendSMS',
  sendEmail: 'https://us-central1-boltbox-admin-dev.cloudfunctions.net/sendEmail',
  createPDFInvoice: 'https://us-central1-boltbox-admin-dev.cloudfunctions.net/createPdfInvoice',
  checkRecaptcha: 'https://us-central1-boltbox-admin-dev.cloudfunctions.net/checkRecaptcha',
  getOrders: 'https://us-central1-boltbox-admin-dev.cloudfunctions.net/getOrders',
  getOrderReportUrl: 'https://us-central1-boltbox-admin-dev.cloudfunctions.net/getOrderReportUrl',
  getVehicleRoutesReport: 'https://us-central1-boltbox-admin-dev.cloudfunctions.net/getVehicleRoutes',
};

const login = {
  resetTime: {
    amount: 30,
    unit: 'seconds'
  }
}

const featureFlags = {
  metrcIsEnabled: true,
}

const environment = {
  customerPortalUrl: 'https://boltbox-cp-dev.web.app/',
  adminPortalUrl: 'https://boltbox-admin-dev.firebaseapp.com/',
};

export {
  api,
  firebase,
  google,
  mapbox,
  sentry,
  site,
  login,
  featureFlags,
  environment,
};
