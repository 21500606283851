import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Loadable from 'react-loadable';

import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import { AppRoute } from 'components/Routes';
import ErrorBoundary from 'components/ErrorBoundary';
import ResourceNotFound from 'components/NotFound';

import firebase from '../../firebase';
import store from '../../store';

import {
  loginPath, homePath,
  newOrderPath, viewOrderPath, editOrderPath,
  reportsPath, orderRequestsPath, orderDraftsPath,
  ordersPath, accountRequestsPath, cashOrdersPath,
  editCashOrderPath
} from 'constants/routes';

import { routesConfig } from "constants/collectionsByRoutes";

import './App.css';

const Login = Loadable({
  loader: () => import('pages/Login'),
  loading: () => null,
});

const Monitoring = Loadable({
  loader: () => import('pages/Monitoring'),
  loading: () => null,
});

const NewOrder = Loadable({
  loader: () => import('pages/Orders/NewOrder'),
  loading: () => null,
});

const ViewOrder = Loadable({
  loader: () => import('pages/Orders/ViewOrder'),
  loading: () => null,
});

const EditOrder = Loadable({
  loader: () => import('pages/Orders/EditOrder'),
  loading: () => null,
});

const OrdersList = Loadable({
  loader: () => import('pages/Orders/OrdersList/OrdersList'),
  loading: () => null,
});

const OrdersRequests = Loadable({
  loader: () => import('pages/Orders/OrdersList/OrdersRequests'),
  loading: () => null,
});

const OrdersDraft = Loadable({
  loader: () => import('pages/Orders/OrdersList/OrdersDraft'),
  loading: () => null,
});

const CashOrders = Loadable({
  loader: () => import('pages/Orders/OrdersList/CashOrders'),
  loading: () => null,
});

const ProcessCashOrders = Loadable({
  loader: () => import('pages/Orders/ProcessCashOrders'),
  loading: () => null,
});

const Reports = Loadable({
  loader: () => import('pages/Reports/Reports'),
  loading: () => null,
});

const AccountRequests = Loadable({
  loader: () => import('pages/Settings/AccountRequests'),
  loading: () => null,
});

class App extends Component {
  constructor() {
    super();
    this.history = createBrowserHistory();

    // react-redux-firebase config
    this.rrfConfig = {
      userProfile: 'users',
      useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
      enableLogging: !['production', 'test'].includes(process.env.NODE_ENV)
    };

    this.rrfProps = {
      firebase,
      config: this.rrfConfig,
      dispatch: store.dispatch,
      createFirestoreInstance // <- needed if using firestore
    };
  }


  render() {
    const PageNotFound = () => <ResourceNotFound message="Sorry but we couldn't find this page." />;

    return (
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...this.rrfProps}>
          <Router history={this.history}>
            <Switch>
              <Route exact path={loginPath} component={Login} />

              <AppRoute exact path={homePath} component={Monitoring} config={routesConfig.monitoring} />
              <AppRoute exact path={reportsPath} component={Reports} />
              <AppRoute exact path={ordersPath} component={OrdersList} config={routesConfig.orderList} />

              <AppRoute exact path={newOrderPath} component={NewOrder} config={routesConfig.orderForm} />
              <AppRoute exact path={viewOrderPath} component={ViewOrder} config={routesConfig.orderForm} />
              <AppRoute exact path={editOrderPath} component={EditOrder} config={routesConfig.orderForm} />

              <AppRoute exact path={orderRequestsPath} component={OrdersRequests} config={routesConfig.orderRequests} />
              <AppRoute exact path={orderDraftsPath} component={OrdersDraft} config={routesConfig.orderDrafts} />
              <AppRoute exact path={cashOrdersPath} component={CashOrders} config={routesConfig.cashOrders} />
              <AppRoute exact path={editCashOrderPath} component={ProcessCashOrders} config={routesConfig.editCashOrderPath} />

              <AppRoute exact path={accountRequestsPath} component={AccountRequests} />
              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </ReactReduxFirebaseProvider>
      </Provider>
    );
  }
}

export default ErrorBoundary(App);
