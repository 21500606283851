
// const string for actions in uppercase
const BRAND_NEW = 'BRAND_NEW';
const BRAND_EDIT = 'BRAND_EDIT';
const BRAND_NAME_CHANGED = 'BRAND_NAME_CHANGED';
const RECIPIENT_SELECTED_ID= 'RECIPIENT_SELECTED_ID';
const RECIPIENT_NEW_ITEM = 'RECIPIENT_NEW_ITEM';
const REMOVE_RECIPIENT_ITEM = 'REMOVE_RECIPIENT_ITEM';

// actions
export const actions = {
  onBrandNew: () => ({ type: BRAND_NEW }),
  onBrandEdit: (data) => ({ type: BRAND_EDIT, data }),
  onNameChanged: (name) => ({ type: BRAND_NAME_CHANGED, name }),
  onRecipientSelectedId: (recipentSelectedId) => ({ type: RECIPIENT_SELECTED_ID, recipentSelectedId }),
  onNewRecipientItem: (item) => ({ type: RECIPIENT_NEW_ITEM, item }),
  onRemoveRecipientItem: (index) => ({ type: REMOVE_RECIPIENT_ITEM, index }),
};

// initial state
const initialState = {
  id: null,
  name: '',
  recipentSelectedId: '',
  recipients: []
};

// action handlers
const actionHandlers = {
  [BRAND_NEW]: (state) => ({ ...state, ...initialState }),
  [BRAND_EDIT]: (state, { data }) => ({ ...state, ...data }),
  [BRAND_NAME_CHANGED]: (state, { name }) => ({ ...state, name }),
  [RECIPIENT_SELECTED_ID]: (state, { recipentSelectedId }) => ({ ...state, recipentSelectedId }),
  [RECIPIENT_NEW_ITEM]: (state, { item }) => ({ ...state, recipients: [ ...state.recipients, item ]}),

  [REMOVE_RECIPIENT_ITEM]: (state, { index }) => {
    return { ...state, recipients: [
      ...state.recipients.slice(0, index),
      ...state.recipients.slice(index + 1)
    ]};
  },
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
};
