export const SET_NEW_CASH_DENOMINATION = 'SET_NEW_CASH_DENOMINATION';
export const SET_REMOVE_CASH_DENOMINATION = 'SET_REMOVE_CASH_DENOMINATION';
export const SET_NOTES = 'SET_NOTES';
export const SET_TRANSACTION_DATE = 'SET_TRANSACTION_DATE';

export const EDIT_CASH_ORDER = 'EDIT_CASH_ORDER';
export const CLEAR_CASH_ORDER = 'CLEAR_CASH_ORDER';

// actions
export const actions = {
  onNewCashDenomination: (item) => ({ type: SET_NEW_CASH_DENOMINATION, item }),
  onRemoveCashDenomination: (index) => ({ type: SET_REMOVE_CASH_DENOMINATION, index }),
  onEditCashOrder: (data) => ({ type: EDIT_CASH_ORDER, data }),
  onSetNotes: (notes) => ({ type: SET_NOTES, notes }),
  onSetTransactionDate: (transactionDate) => ({ type: SET_TRANSACTION_DATE, transactionDate }),
  clearCashOrder: () => ({ type: CLEAR_CASH_ORDER }),
};

// initial state
const initialState = {
  orderNumber: '',
  sender: '',
  transactionType: '',
  requestedPickup: '',
  accountNumber: '',
  financialInstitution: '',
  depositDate: '',
  depositAmount: '',
  status: '',
  notes: '',
  transactionDate: '',
  denominations: []
};

// action handlers
const actionHandlers = {
  [EDIT_CASH_ORDER]: (state, { data }) => ({ ...state, ...data }),
  [CLEAR_CASH_ORDER]: () => ({ ...initialState }),
  [SET_NOTES]: (state, { notes }) => ({ ...state, notes }),
  [SET_TRANSACTION_DATE]: (state, { transactionDate }) => ({ ...state, transactionDate }),
  [SET_NEW_CASH_DENOMINATION]: (state, {item}) => {
    const existingItem = state.denominations.find(d => d.denomination === item.denomination);

    return {
      ...state,
      denominations: existingItem
        ? state.denominations.map(d => d.denomination === item.denomination
          ? { ...d, quantity: Number(d.quantity) + Number(item.quantity), amount: Number(d.amount) + Number(item.amount) }
          : d
        )
        : [...state.denominations, item],
    };
  },
  [SET_REMOVE_CASH_DENOMINATION]: (state, { index }) => {
    const updatedCashDenominations = [
      ...state.denominations.slice(0, index),
      ...state.denominations.slice(index + 1),
    ];
    return {
      ...state,
      denominations: updatedCashDenominations,
    };
  }
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state
};
