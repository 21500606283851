// const string for actions in uppercase
const VEHICLE_NEW = 'VEHICLE_NEW';
const VEHICLE_EDIT = 'VEHICLE_EDIT';
const VEHICLE_CLEAR = 'VEHICLE_CLEAR';
const VEHICLE_DESCRIPTION = 'VEHICLE_DESCRIPTION';
const VEHICLE_MODEL = 'VEHICLE_MODEL';
const VEHICLE_MAKER = 'VEHICLE_MAKER';
const VEHICLE_COLOR = 'VEHICLE_COLOR';
const VEHICLE_ACTIVE = 'VEHICLE_ACTIVE';
const VEHICLE_LICENSE_PLATE = 'VEHICLE_LICENSE_PLATE';
const VEHICLE_VIN_NUMBER = 'VEHICLE_VIN_NUMBER';
const VEHICLE_TERMINAL_LOCATION = 'VEHICLE_TERMINAL_LOCATION';


// actions
export const actions = {
  onVehicleNew: () => ({ type: VEHICLE_NEW }),
  onVehicleEdit: (data) => ({ type: VEHICLE_EDIT, data }),
  onVehicleDescriptionChanged: (description) => ({ type: VEHICLE_DESCRIPTION, description }),
  onVehicleColorChanged: (color) => ({ type: VEHICLE_COLOR, color }),
  onVehicleModelChanged: (model) => ({ type: VEHICLE_MODEL, model }),
  onVehicleMakerChanged: (maker) => ({ type: VEHICLE_MAKER, maker }),
  onVehicleLicensePlateChanged: (licensePlate) => ({ type: VEHICLE_LICENSE_PLATE, licensePlate }),
  onVehicleActiveChanged: (active) => ({ type: VEHICLE_ACTIVE, active }),
  onVehicleVinNumberChanged: (vinNumber) => ({ type: VEHICLE_VIN_NUMBER, vinNumber }),
  onVehicleTerminalLocationChanged: (terminalLocationId) => ({ type: VEHICLE_TERMINAL_LOCATION, terminalLocationId }),
  clear: () => ({ type: VEHICLE_CLEAR }),
};

// initial state
const initialState = {
  id: null,
  model: '',
  maker: '',
  licensePlate: '',
  color: '',
  description: '',
  active: true,
  vinNumber: '',
  terminalLocationId: '',
};

// action handlers
const actionHandlers = {
  [VEHICLE_NEW]: (state) => ({ ...state, ...initialState }),
  [VEHICLE_EDIT]: (state, { data }) => ({ ...state, ...data }),
  [VEHICLE_DESCRIPTION]: (state, { description }) => ({ ...state, description }),
  [VEHICLE_MODEL]: (state, { model }) => ({ ...state, model }),
  [VEHICLE_MAKER]: (state, { maker }) => ({ ...state, maker }),
  [VEHICLE_COLOR]: (state, { color }) => ({ ...state, color }),
  [VEHICLE_ACTIVE]: (state, { active }) => ({ ...state, active }),
  [VEHICLE_LICENSE_PLATE]: (state, { licensePlate }) => ({ ...state, licensePlate }),
  [VEHICLE_VIN_NUMBER]: (state, { vinNumber }) => ({ ...state, vinNumber }),
  [VEHICLE_TERMINAL_LOCATION]: (state, { terminalLocationId }) => ({ ...state, terminalLocationId }),
  [VEHICLE_CLEAR]: (state) => ({ ...state, ...initialState }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
};
