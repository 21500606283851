// const string for actions in uppercase
const EMAIL_CHANGED = 'EMAIL_CHANGED';
const FORM_CLEAR = 'FORM_CLEAR';
const FORM_ERROR = 'FORM_ERROR';
const FORM_SUCCESS = 'FORM_SUCCESS';

// actions
export const actions = {
  onEmailResetChanged: (emailReset) => ({ type: EMAIL_CHANGED, emailReset: emailReset }),
  onError: (error) => ({ type: FORM_ERROR, error }),
  onSuccess: (success) => ({ type: FORM_SUCCESS, success }),
  clear: () => ({ type: FORM_CLEAR }),
};

// initial state
const initialState = {
  emailReset: '',
  error: null,
  success: null,
};

// action handlers
const actionHandlers = {
  [EMAIL_CHANGED]: (state, { emailReset }) => ({ ...state, emailReset: emailReset }),
  [FORM_CLEAR]: (state) => ({ ...state, emailReset: '', error: null, success: null }),
  [FORM_ERROR]: (state, { error }) => ({ ...state, error: error }),
  [FORM_SUCCESS]: (state, { success }) => ({ ...state, success: success }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
