import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Search extends Component {
  static propTypes = { 
    onClick: PropTypes.func,
  };

  render() {
    const { onClick } = this.props;
    return <div className="">
      <button className="btn btn-link search-link d-lg-inline-block d-none" data-toggle="search" onClick={() => onClick()}><i className="pg-search"></i>Type anywhere to <span className="bold">search</span></button>
    </div>;
  }
}

export default Search;
