// const string for actions in uppercase
const RECAPTCHA_LOADED = 'RECAPTCHA_LOADED';

// actions
export const actions = {
  onRecaptchaLoaded: recaptcha => ({ type: RECAPTCHA_LOADED, recaptcha }),
};

// initial state
const initialState = {
  recaptcha: null,
};

// action handlers
const actionHandlers = {
  [RECAPTCHA_LOADED]: (state, { recaptcha }) => {
    return { ...state, recaptcha };
  },
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};
