import React, { useState } from 'react';
import { Input } from 'reactstrap';

const SearchBar = (props) => {
    const { onUpdateFilterText } = props;
    const [inputValue, setInputValue] = useState("");

    const onChange = (event) => {
      event.preventDefault();
      const { target: { value } } = event;
      setInputValue(value);

      onUpdateFilterText(value);
    }

    return (
      <Input
        id="search-users"
        name="search-users"
        placeholder="Search by name, email or phone number"
        value={inputValue}
        onChange={onChange}
      />
    )
}

export default SearchBar;