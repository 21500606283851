import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import { Button } from 'reactstrap';
import WithLoader from 'components/WithLoader';
import User from './User';
import { actions as accountActions } from 'reducers/account';
import { actions as fetchingActions } from 'reducers/fetching';
import * as roles from 'constants/roles';

class Drivers extends User {
  static defaultProps = {
    role: roles.DRIVER
  }

  getAccounts() {
    const drivers = this.props.users.filter(u => u.role === roles.DRIVER && !u.disabled);
    return drivers;
  }

  getNewAccountButton() {
    return <Button color="primary" onClick={() => this.showAccount()}>New Driver</Button>;
  }
}

const mapStateToProps = ({ account, firestore: { ordered } }) => {
  const { terminals } = ordered;

  return {
    account,
    terminals
  }
};

const mapDispatchToProps = ({
  ...accountActions,
  ...fetchingActions,
});

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps),
)(Drivers);

export default WithLoader(FirestoreConnected);
