// const string for actions in uppercase
const PROFILE_EMAIL_CHANGED = 'PROFILE_EMAIL_CHANGED';
const PROFILE_NAME_CHANGED = 'PROFILE_NAME_CHANGED';
const PROFILE_FIRST_NAME_CHANGED = 'PROFILE_FIRST_NAME_CHANGED';
const PROFILE_LAST_NAME_CHANGED = 'PROFILE_LAST_NAME_CHANGED';
const PROFILE_MIDDLE_NAME_CHANGED = 'PROFILE_MIDDLE_NAME_CHANGED';
const PROFILE_PHONE_CHANGED = 'PROFILE_PHONE_CHANGED';
const PROFILE_AVATAR_CHANGED = 'PROFILE_AVATAR_CHANGED';
const PROFILE_FORM_CLEAR = 'PROFILE_FORM_CLEAR';
const PROFILE_FORM_ERROR = 'PROFILE_FORM_ERROR';
const PROFILE_FORM_SUCCESS = 'PROFILE_FORM_SUCCESS';

// actions
export const actions = {
  onEmailChanged: (email) => ({ type: PROFILE_EMAIL_CHANGED, email }),
  onNameChanged: (name) => ({ type: PROFILE_NAME_CHANGED, name }),
  onFirstNameChanged: (firstName) => ({ type: PROFILE_FIRST_NAME_CHANGED, firstName }),
  onMiddleNameChanged: (middleName) => ({ type: PROFILE_MIDDLE_NAME_CHANGED, middleName }),
  onLastNameChanged: (lastName) => ({ type: PROFILE_LAST_NAME_CHANGED, lastName }),
  onPhoneChanged: (phone) => ({ type: PROFILE_PHONE_CHANGED, phone }),
  onAvatarUrlChanged: (avatarUrl) => ({ type: PROFILE_AVATAR_CHANGED, avatarUrl }),
  onError: (error) => ({ type: PROFILE_FORM_ERROR, error }),
  onSuccess: (success) => ({ type: PROFILE_FORM_SUCCESS, success }),
  clear: () => ({ type: PROFILE_FORM_CLEAR }),
};

// initial state
const initialState = {
  email: '',
  error: null,
  success: null,
};

// action handlers
const actionHandlers = {
  [PROFILE_EMAIL_CHANGED]: (state, { email }) => ({ ...state, email }),
  [PROFILE_NAME_CHANGED]: (state, { name }) => ({ ...state, name }),
  [PROFILE_FIRST_NAME_CHANGED]: (state, { firstName }) => ({ ...state, firstName }),
  [PROFILE_LAST_NAME_CHANGED]: (state, { lastName }) => ({ ...state, lastName }),
  [PROFILE_MIDDLE_NAME_CHANGED]: (state, { middleName }) => ({ ...state, middleName }),
  [PROFILE_PHONE_CHANGED]: (state, { phone }) => ({ ...state, phone }),
  [PROFILE_AVATAR_CHANGED]: (state, { avatarUrl }) => ({ ...state, avatarUrl }),
  [PROFILE_FORM_CLEAR]: (state) => ({ ...state, email: '', error: null, success: null }),
  [PROFILE_FORM_ERROR]: (state, { error }) => ({ ...state, error: error }),
  [PROFILE_FORM_SUCCESS]: (state, { success }) => ({ ...state, success: success }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
