import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  firestoreConnect,
  withFirebase,
  withFirestore,
  isLoaded,
} from "react-redux-firebase";
import { Button, Input, FormGroup, Label, Table } from "reactstrap";
import { NotificationManager, NotificationPosition } from "components/Layout";
import WithLoader from "components/WithLoader";
import { actions as brandActions } from "reducers/brand";
import { actions as fetchingActions } from "reducers/fetching";
import * as collections from "constants/collections";
import { now, collator } from "utils";
import DataTable from "react-data-table-component";
import Select from "components/Select";

class Brands extends Component {
  constructor(props) {
    super(props);
    this.showBrand = this.showBrand.bind(this);
    this.showList = this.showList.bind(this);

    this.state = {
      activeDiv: "list",
    };
  }

  static propTypes = {
    role: PropTypes.string,
    className: PropTypes.string,
  };

  showList() {
    this.setState({ activeDiv: "list" });
    this.props.onBrandNew();
  }

  saveBrand = async () => {
    const { firestore, brand, onBrandNew } = this.props;

    let data = {
      name: brand.name,
      createdAt: now(),
      modifiedAt: now(),
      recipients: [],
      disabled: false
    };

    this.props.setLoading(true);

    if (brand && brand.id) {
      await firestore.collection(collections.BRAND).doc(brand.id).update(data);

      onBrandNew();
      this.showList();
      NotificationManager.success(
        "Brand successfully Updated",
        null,
        NotificationPosition.TOP_LEFT
      );
    } else {
      await firestore.add(collections.BRAND, data);
      onBrandNew();
      this.showList();
      NotificationManager.success(
        "Brand successfully created",
        null,
        NotificationPosition.TOP_LEFT
      );
    }
    this.props.setLoading(false);
  };

  showBrand = (brand) => {
    const { onBrandNew, onBrandEdit } = this.props;
    this.setState({ activeDiv: "form" });
    if (!!brand) {
      onBrandEdit(brand);
    } else {
      onBrandNew();
    }
  };

  getList = () => {
    const { brands } = this.props;
    const onRowClicked = (brand) => {
      this.showBrand(brand);
    };

    const newList = [...brands];
    newList.sort((a, b) => collator.compare(a.name, b.name));


    this.getRecipientsNameOptions();
    const columns = [
      {
        name: "#",
        maxWidth: "5%",
        center: true,
        selector: "idx",
        sortable: true,
      },
      {
        name: "Name",
        left: true,
        selector: "name",
        sortable: true,
      },
      {
        name: "Actions",
        left: true,
        button: true,
        cell: (brand) => (
          <button
            className="btn btn-link"
            href="#"
            onClick={() => this.deleteBrand(brand)}
          >
            <i className="fa fa-trash" />
          </button>
        ),
      },
    ];
    return (
      <>
        <DataTable
          columns={columns}
          dense
          data={newList.map((item, idx) => ({ idx: idx + 1, ...item }))}
          highlightOnHover
          noHeader
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 25, 50]}
          responsive
          pointerOnHover
          onRowClicked={onRowClicked}
        />
      </>
    );
  };

  getRecipientsNameOptions = (newOption) => {
    const { recipients } = this.props;
    let options = [];

    if (newOption) {
      options.push(newOption);
    }

    for (let i in recipients) {
      const { id, name } = recipients[i];
      options.push({ value: id, label: name });
    }

    return options.sort((a, b) => (a.label > b.label ? 1 : -1));
  };

  getForm = () => {
    const { brand, onNameChanged } = this.props;

    return (
      <div>
        <form
          className="p-t-15"
          onSubmit={(e) => {
            e.preventDefault();
            this.saveBrand();
          }}
        >
          <FormGroup className={"form-group form-group-default input-group"}>
            <div className={"form-input-group"}>
              <label>Name</label>
              <Input
                placeholder="Brand Name"
                type="text"
                required
                name="name"
                className="form-control"
                defaultValue={brand.name || ""}
                onChange={(event) => {
                  onNameChanged(event.target.value);
                }}
              />
            </div>
          </FormGroup>

          {brand.id != null &&

            <FormGroup
              id={`}RecipientFormGroup`}
              className="form-group-default form-group-default-select2"
            >
              <Label for="recipient">Customer Name</Label>
              <Select
                data-test-id="recipient"
                name="recipient"
                onChange={(event) => {
                  this.handleRecipientAdd(event.target.value);
                }}
                creatable
                placeholder={"Select"}
                onCreate={(params) => {
                  const { term } = params;
                  if (term.trim().length === 0) {
                    return null;
                  }
                  return {
                    id: term.trim(),
                    text: `${term.trim()} (New Recipient)`,
                  };
                }}
                options={this.getRecipientsNameOptions(false)}
                readOnly={false}
              />
            </FormGroup>
          }

          <Button color="primary" type="submit">
            {brand.id ? "Update" : "Create"}
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={() => this.showList()}
          >
            Cancel
          </Button>
          <br />
        </form>
      </div>
    );
  };

  handleRecipientAdd(recipientId) {
    const { firestore, brand, onNewRecipientItem } = this.props;

    const recipient = brand.recipients.find(r => r === recipientId);

    if (recipient) {
      NotificationManager.error('Row already added', null, NotificationPosition.TOP_LEFT);
      return;
    }

    firestore
      .collection(collections.BRAND)
      .doc(brand.id)
      .update({
        recipients: firestore.FieldValue.arrayUnion(recipientId),
      })
      .then((result) => {
        onNewRecipientItem(recipientId);
        NotificationManager.success('Row successfully added', null, NotificationPosition.TOP_LEFT);
      });
  }

  getRecipients() {
    let options = [];
    const { brand, recipients } = this.props;
    brand.recipients.map((item, idx) => {

      const recipient = recipients.find(r => r.id === item);
      if (recipient) {
        options.push(recipient)
      }

      return options;
    })

    return options;
  }

  handleRecipientDelete(index, recipient) {
    const { firestore, brand, onRemoveRecipientItem } = this.props;
    if (window.confirm('Please confirm to delete this row')) {
      firestore
      .collection(collections.BRAND)
      .doc(brand.id)
      .update({
        recipients: firestore.FieldValue.arrayRemove(recipient.id),
      })
      .then((result) => {
        onRemoveRecipientItem(index);
        NotificationManager.success('Row successfully Deleted', null, NotificationPosition.TOP_LEFT);
      });
    }
  }

  getCustomersList() {
    const recipients = this.getRecipients();
    return (
      <div className="row">
        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Licensing</th>
              <th>Email</th>
              <th>Actions</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {recipients.map((item, idx) => (
              <tr key={idx}>
                <td>{idx}</td>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <td>{item.metrcLicenseNumber}</td>
                <td>{item.email}</td>
                <td>
                  <button
                    className={"btn btn-link"}
                    href="#"
                    onClick={() => this.handleRecipientDelete(idx, item)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  deleteBrand(item) {
    if (window.confirm("Please confirm to delete this Brand")) {
      const { firestore } = this.props;
      firestore
        .collection(collections.BRAND)
        .doc(item.id)
        .delete()
        .then(() =>
          NotificationManager.success(
            "Brand successfully Deleted",
            null,
            NotificationPosition.TOP_LEFT
          )
        );
    }
  }

  render() {
    const { brands, brand } = this.props;
    const { activeDiv } = this.state;

    if (isLoaded(brands)) {
      return (
        <div>
          <div>
            {activeDiv !== "form" ? (
              <Button color="primary" onClick={() => this.showBrand()}>
                {" "}
                New Brand{" "}
              </Button>
            ) : (
              <Button color="secondary" onClick={() => this.showList()}>
                Back
              </Button>
            )}
          </div>
          {activeDiv === "form" ? this.getForm() : this.getList()}
          <br />
          {brand.id != null && this.state.activeDiv === "form"
            ? this.getCustomersList()
            : null}
        </div>
      );
    }
  }
}

const allBrands = 'allBrands';

const mapStateToProps = ({ brand, firestore: { ordered } }) => {
  const { recipients } = ordered;
  const brands = ordered[allBrands] || [];

  return {
    brand,
    brands,
    recipients
  }
};

const mapDispatchToProps = {
  ...brandActions,
  ...fetchingActions,
};

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect([{
    collection: collections.BRAND,
    orderBy: ['name', 'asc'],
    storeAs: allBrands
  },]),
  connect(mapStateToProps, mapDispatchToProps)
)(Brands);

export default WithLoader(FirestoreConnected);
