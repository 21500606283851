import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as collections from 'constants/collections';
import { withFirestore } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from 'react-redux';
import { actions as appActions } from 'reducers/appState';

class SearchOverlay extends Component {

  constructor(props) {
    super(props);
    this.escFunction = this.escFunction.bind(this);
    this.keyPressed = this.keyPressed.bind(this);
    this.search = this.search.bind(this);
  }
  escFunction(event){
    if(event.keyCode === 27) {
      this.hide()
    }
  }
  static propTypes = {
    visible: PropTypes.bool,
  };

  state = {
    searchText: '',
    results: []
  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  hide() {
    this.props.setSearchOverlayVisibility(false);
  }

  search(event) {
    if (event.target !== null) {
      this.setState({searchText: event.target.value});
    }
  }

  keyPressed(event) {
    if (event.key === "Enter") {
      const {
        firestore,
      } = this.props;
      let results = [];
      let resultUsers = firestore.collection(collections.USER).orderBy('email')
        .startAt(this.state.searchText).endAt(this.state.searchText+"\uf8ff").get();
      let resultDestinations = firestore.collection(collections.DESTINATION).orderBy('name')
        .startAt(this.state.searchText).endAt(this.state.searchText+"\uf8ff").get();
      let resultRecipients = firestore.collection(collections.RECIPIENT).orderBy('name')
        .startAt(this.state.searchText).endAt(this.state.searchText+"\uf8ff").get();
      Promise.all([resultUsers, resultDestinations, resultRecipients]).then(data => {
        const usersResult = data[0].docs;
        results = results.concat(usersResult.map(item => ({
              title: `${item.data().firstName} ${item.data().lastName}`,
              type: 'Users',
              icon: 'fa-car'
            })));
        results = results.concat(data[1].docs.map(item => ({
          title: `${item.data().name}`,
          type: 'Destinations',
          icon: 'fa-map-marker'
        })));
        results = results.concat(data[2].docs.map(item => ({
          title: `${item.data().name}`,
          type: 'Recipients',
          icon: 'fa-user'
        })));
        usersResult.forEach(item => {
          firestore.collection(collections.TASK).where('driverId', '==', item.id).get()
            .then(result => {
              const taskResults = result.docs.map(item => ({
                title: `Task ${item.id}`,
                type: 'Tasks',
                icon: ''
              }));
              this.setState(prevState => ({...prevState, results: prevState.results.concat(taskResults)}));
            });
        });
        this.setState(prevState => ({...prevState, results}));
      });
    }
  }

  getResultItem(item) {
    return <div className="">
      <div className="thumbnail-wrapper d48 circular bg-success text-white inline m-t-10">
        <div>
          {item.icon ? <i className={'fa large-text ' + item.icon }></i> : <div>T</div>}
        </div>
      </div>
      <div className="p-l-10 inline p-t-5">
        <h5 className="m-b-5"><span className="semi-bold result-name">{item.title}</span> on {item.type}</h5>
        <p>&nbsp;</p>
      </div>
    </div>;
  }

  render() {
    const { appState: { isSearchOverlayVisible } } = this.props;
    if (!isSearchOverlayVisible) {
      return null;
    }

    return <div className="overlay" data-pages="search">
      <div className="overlay-content has-results m-t-20">
        <div className="container-fluid">
          <button onClick={() => this.hide()} className="btn btn-link close-icon-light overlay-close text-black fs-16">
            <i className="pg-close"></i>
          </button>
        </div>
        <div className="container-fluid">
          <input id="overlay-search" className="no-border overlay-search bg-transparent" placeholder="Search..."
                 autoComplete="off" spellCheck="false" autoFocus
                 onKeyPress={this.keyPressed} onChange={event => this.search(event)}/>
          <br />
          <div className="inline-block m-l-10">
            <p className="fs-13">Press enter to search</p>
          </div>
        </div>
        <div className="container-fluid">
          <div className="search-results m-t-40">
            {
              this.state.results.length > 0 && <p className="bold">Results</p>
            }
            <div className="row">
              <div className="col-md-6">
                {
                  this.state.results && this.state.results.map(this.getResultItem)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

const mapStateToProps = ({ appState }) => ({ appState });

const mapDispatchToProps = ({
  ...appActions,
});

export default compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchOverlay);

