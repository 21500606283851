import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import { firebase as config } from 'config';
const { ...firebaseConfig } = config;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const firestore = firebase.firestore();

if (process.env.REACT_APP_FIREBASE_EMULATORS === 'on') {
  firestore.useEmulator('localhost', 8088);
  auth.useEmulator('http://localhost:9099');
  firebase.functions().useEmulator('localhost', 5001);
}

export {
  auth,
  firestore,
};

export default firebase;
