import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

class Notification extends Component {
  static propTypes = {
    type: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    const { children, type, timeOut, order } = this.props;
    const { visible } = this.state;

    setTimeout(function () {
      this.setState({ visible: false });
    }.bind(this), timeOut);

    return <div className="pgn push-on-sidebar-open pgn-simple" data-position={order}>
      <Alert color={type === 'error' ? 'danger' : type} isOpen={visible} toggle={this.onDismiss}>
        {children}
      </Alert>
    </div>;
  }
}

export default Notification;
