const INVOICE_DETAIL_NAME_CHANGED = 'INVOICE_DETAIL_NAME_CHANGED';
const INVOICE_DETAIL_TRANSPORTATION_PRICE_CHANGED = 'INVOICE_DETAIL_TRANSPORTATION_PRICE_CHANGED';
const INVOICE_DETAIL_FORM_CLEAR = 'INVOICE_DETAIL_FORM_CLEAR';

// actions
export const actions = {
  onInvoiceNameDetailChanged: (name) => ({ type: INVOICE_DETAIL_NAME_CHANGED, name }),
  onInvoiceTransportationChanged: (transportationPrice) => ({ 
    type: INVOICE_DETAIL_TRANSPORTATION_PRICE_CHANGED,  transportationPrice: parseFloat(transportationPrice)
  }),
  invoiceDetailClear: () => ({ type: INVOICE_DETAIL_FORM_CLEAR }),
};

// initial state
const initialState = {
  name: '',
  transportationPrice: '',
};

// action handlers
const actionHandlers = {
  [INVOICE_DETAIL_NAME_CHANGED]: (state, { name }) => ({ ...state, name }),
  [INVOICE_DETAIL_TRANSPORTATION_PRICE_CHANGED]: (state, { transportationPrice }) => ({ ...state, transportationPrice }),
  [INVOICE_DETAIL_FORM_CLEAR]: () => ({ ...initialState }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state
};
