// const string for actions in uppercase
const LOGIN_EMAIL_CHANGED = 'LOGIN_EMAIL_CHANGED';
const LOGIN_PASSWORD_CHANGED = 'LOGIN_PASSWORD_CHANGED';
const LOGIN_KEEP_ME_SIGNEDIN_CHANGED = 'LOGIN_KEEP_ME_SIGNEDIN_CHANGED';
const LOGIN_CLEAR = 'LOGIN_CLEAR';

// actions
export const actions = {
  onEmailChanged: (email) => ({ type: LOGIN_EMAIL_CHANGED, email }),
  onPasswordChanged: (password) => ({ type: LOGIN_PASSWORD_CHANGED, password }),
  onKeepMeSignedInChanged: (keepMeSignedIn) => ({ type: LOGIN_KEEP_ME_SIGNEDIN_CHANGED, keepMeSignedIn }),
  clear: () => ({ type: LOGIN_CLEAR }),
};

// initial state
const initialState = {
  email: '',
  password: '',
  keepMeSignedIn: false,
};

// action handlers
const actionHandlers = {
  [LOGIN_EMAIL_CHANGED]: (state, { email }) => ({ ...state, email }),
  [LOGIN_PASSWORD_CHANGED]: (state, { password }) => ({ ...state, password }),
  [LOGIN_KEEP_ME_SIGNEDIN_CHANGED]: (state, { keepMeSignedIn }) => ({ ...state, keepMeSignedIn }),
  [LOGIN_CLEAR]: (state) => ({ ...state, email: '', password: '' }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
