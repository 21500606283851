// const string for actions in uppercase
const SETTINGS_NEW = 'SETTINGS_NEW';
const SETTINGS_EDIT = 'SETTINGS_EDIT';
const SETTINGS_EMAIL_CHANGED = 'SETTINGS_EMAIL_CHANGED';
const SETTINGS_SUPPORT_EMAIL_CHANGED = 'SETTINGS_SUPPORT_EMAIL_CHANGED';
const SETTINGS_COMPANY_NAME_CHANGED = 'SETTINGS_COMPANY_NAME_CHANGED';
const SETTINGS_CLEAR = 'SETTINGS_CLEAR';
const SETTINGS_EMAIL_REPORT_CHANGED = 'SETTINGS_EMAIL_REPORT_CHANGED';
const SETTINGS_HOUR_STATUS_HANGED = 'SETTINGS_HOUR_STATUS_HANGED';
const SETTINGS_TAX_PERCENT_HANGED = 'SETTINGS_TAX_PERCENT_HANGED';
const SETTINGS_PHONE_HANGED = 'SETTINGS_PHONE_HANGED';
const SETTINGS_FUEL_SURCHARGE_HANGED = 'SETTINGS_FUEL_SURCHARGE_HANGED';
const SETTINGS_ARRIVING_NOTIFICATION_INTERVAL_CHANGED = 'SETTINGS_ARRIVING_NOTIFICATION_INTERVAL_CHANGED';
const SETTINGS_TIMEZONE_HANGED = 'SETTINGS_TIMEZONE_HANGED';

// actions
export const actions = {
  onSettingsNew: () => ({ type: SETTINGS_NEW }),
  onSettingsEdit: (data) => ({ type: SETTINGS_EDIT, data }),
  onSettingsEmailChanged: (companyEmail) => ({ type: SETTINGS_EMAIL_CHANGED, companyEmail }),
  onSettingsSupportEmailChanged: (supportEmail) => ({ type: SETTINGS_SUPPORT_EMAIL_CHANGED, supportEmail }),
  onSettingsNameChanged: (companyName) => ({ type: SETTINGS_COMPANY_NAME_CHANGED, companyName }),
  onSettingsEmailReportChanged: (emailReport) => ({ type: SETTINGS_EMAIL_REPORT_CHANGED, emailReport }),
  onSettingsHourStatusChanged: (hourStatus) => ({ type: SETTINGS_HOUR_STATUS_HANGED, hourStatus }),
  onSettingsPhoneNumberChanged: (phoneNumber) => ({ type: SETTINGS_PHONE_HANGED, phoneNumber }),
  onSettingsTaxPercentChanged: (taxPercentage) => ({ type: SETTINGS_TAX_PERCENT_HANGED, taxPercentage: parseFloat(taxPercentage) }),
  onSettingsFuelSurchargeChanged: (fuelSurcharge) => ({ type: SETTINGS_FUEL_SURCHARGE_HANGED, fuelSurcharge: parseFloat(fuelSurcharge) }),
  onSettingsArrivingNotificationIntervalChanged: (arrivingNotificationInterval) => ({ type: SETTINGS_ARRIVING_NOTIFICATION_INTERVAL_CHANGED, arrivingNotificationInterval: parseInt(arrivingNotificationInterval) }),
  onSettingsTimeZoneChanged: (timeZone) => ({ type: SETTINGS_TIMEZONE_HANGED, timeZone }),
  clear: () => ({ type: SETTINGS_CLEAR }),
};

// initial state
const initialState = {
  companyName: '',
  companyEmail: '',
  supportEmail: '',
  emailReport: 'weekly_mail',
  hourStatus: 'no_hours_selected',
  phoneNumber: '',
  taxPercentage: 0.0,
  fuelSurcharge: null,
  arrivingNotificationInterval: '',
  timeZone: 'America/Detroit',
};

// action handlers
const actionHandlers = {
  [SETTINGS_NEW]: (state) => ({ ...state, ...initialState }),
  [SETTINGS_EDIT]: (state, { data }) => ({ ...state, ...data }),
  [SETTINGS_EMAIL_CHANGED]: (state, { companyEmail }) => ({ ...state, companyEmail }),
  [SETTINGS_SUPPORT_EMAIL_CHANGED]: (state, { supportEmail }) => ({ ...state, supportEmail }),
  [SETTINGS_COMPANY_NAME_CHANGED]: (state, { companyName }) => ({ ...state, companyName }),
  [SETTINGS_EMAIL_REPORT_CHANGED]: (state, { emailReport }) => ({ ...state, emailReport }),
  [SETTINGS_HOUR_STATUS_HANGED]: (state, { hourStatus }) => ({ ...state, hourStatus }),
  [SETTINGS_TAX_PERCENT_HANGED]: (state, { taxPercentage }) => ({ ...state, taxPercentage }),
  [SETTINGS_PHONE_HANGED]: (state, { phoneNumber }) => ({ ...state, phoneNumber }),
  [SETTINGS_FUEL_SURCHARGE_HANGED]: (state, { fuelSurcharge }) => ({ ...state, fuelSurcharge }),
  [SETTINGS_ARRIVING_NOTIFICATION_INTERVAL_CHANGED]: (state, { arrivingNotificationInterval }) => ({ ...state, arrivingNotificationInterval }),
  [SETTINGS_TIMEZONE_HANGED]: (state, { timeZone }) => ({ ...state, timeZone }),
  [SETTINGS_CLEAR]: (state) => ({ ...state, ...initialState }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
};
