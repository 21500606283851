import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import cls from 'classnames';
import * as collections from 'constants/collections';
import Layout from 'components/Layout';
import SideMenu from 'components/SideMenu'; // new MUI drawer component
import Settings from 'components/Settings';
import Loading from 'components/Loading';

const AppLayout = (props) => {
  const [sidebarIsOpen] = React.useState(false);
  const [settingsModal, setSettingsModal] = React.useState(false);
  const firestore = useFirestore();
  const { config: { getCollections, getComponentProps } = {}, component: Component } = props;

  React.useEffect(() => {
    firestore.get(collections.USER);
  }, [firestore, Component]);

  useFirestoreConnect(() => getCollections ? getCollections() : []);

  const { profile, auth } = useSelector(({ firebase: { profile, auth } }) => ({ profile, auth, task }));
  const { ordered, data } = useSelector(({ firestore: { ordered, data } }) => ({ ordered, data }))
  const { task } = useSelector(({ task }) => ({ task }))

  const currentUser = { ...profile, id: auth.uid };
  const componentProps = getComponentProps ? getComponentProps({ ordered, data, currentUser, task }) : {};


  React.useEffect(() => {
    document.body.classList.add('dashboard');
    document.body.classList.add('no-header');

    return () => document.body.classList.remove('dashboard');
  }, []);


  const toggleSettings = () => {
    setSettingsModal(!settingsModal);
  }

  if (!isLoaded(Object.values(componentProps))) {
    return (<Loading />);
  }

  return (
    <Layout isProgressBarVisible={false}>
      <SideMenu toggleSettings={toggleSettings} />
      <nav data-pages="sidebar" className="page-sidebar">
        <div className={cls('sidebar-menu', { open: sidebarIsOpen })}>
          <div className="clearfix"></div>
        </div>
      </nav>
      <div
        className={cls(
          'page-container', {
          'open-sidebar': sidebarIsOpen,
        })}>
        <div className="page-content-wrapper full-height">
          <div className="content full-width full-height">
            <Component {...componentProps} />
          </div>
        </div>
      </div>
      <Settings isOpen={settingsModal} toggle={toggleSettings} currentUser={currentUser} className={'modal-lg settings-dialog'} />
    </Layout>
  );
}

export default AppLayout;
