import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'reducers/destination';
import { getLine1FromAddress } from 'utils';
import countries from 'constants/countries';

import { Input, FormGroup, Row, Col, Label } from 'reactstrap';
import { Address } from "components/Select";

class Destination extends Component {
  static propTypes = {
    id: PropTypes.string,
    as: PropTypes.string.isRequired,
  };

  withDestination = (action, value) => {
    const { as: destination } = this.props;
    action(destination, value);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { onDestinationEdit, destination } = this.props;
    const { defaultData: nextDestination } = nextProps;
    if (nextDestination && nextDestination.name && !(destination.name || '').length) {
      this.withDestination(onDestinationEdit, nextDestination);
    }
  }

  componentWillUnmount() {
    const { clear } = this.props;
    this.withDestination(clear);
  }

  render() {
    const {
      id,
      disabled,
      readOnly,
      defaultData: destination,
      destination: {
        address,
        zone,
        name,
        loading,
      },
      onLine1Changed,
      onCountryChanged,
      onStateChanged,
      onPostalCodeChanged,
      onZoneChanged,
      onDestinationNameChanged,
      onLoadDataFromAddress, } = this.props;
    const { line1,
      country,
      state,
      postalCode } = address || {};
    let countryLabel = '';

    if (country || (destination && destination.country)) {
      const countryObj = countries.find(c => c.value === (address.country || 'US'));
      countryLabel = countryObj && countryObj.label;
    }

    return <Fragment>
      <FormGroup className={`form-group-default form-group-default-select2${disabled ? ' readonly' : ''}`}
        id={`${id}-wrapper`} data-test-id={`${id}-wrapper`}>
        <label>Address</label>
        <Address
          id={`${id}Address`}
          data-test-id={`${id}Address`}
          value={line1 || (destination && destination.address && destination.address.line1) || ''}
          readOnly={disabled}
          onChange={event => {
            const { target: { value: address } } = event;
            this.withDestination(onLine1Changed, getLine1FromAddress(address));
            address && this.withDestination(onLoadDataFromAddress, address);
          }} />
      </FormGroup>
      {loading && <label>Getting data from address...</label>}
      {/* State, ZipCode and Country are always readonly, just name is editable */}
      <Row>
        <Col sm={4}>
          <FormGroup className="form-group-default readonly">
            <label>State</label>
            <Input
              type="text"
              name="state"
              id={`${id}State`}
              data-test-id={`${id}State`}
              placeholder="State"
              readOnly
              // required
              value={state || (destination && destination.address && destination.address.state) || ''}
              onChange={event => { this.withDestination(onStateChanged, event.target.value) }}
            />
          </FormGroup>
          <FormGroup className="form-group-default readonly">
            <Label for="country">Country</Label>
            <Input
              type="text"
              name="country"
              id={`${id}Country`}
              data-test-id={`${id}Country`}
              value={countryLabel}
              readOnly
              placeholder="Country"
              // required
              onChange={event => { this.withDestination(onCountryChanged, event.target.value) }}
            />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup className="form-group-default readonly">
            <label>Zip Code</label>
            <Input
              type="text"
              name="zip"
              id={`${id}Zip`}
              data-test-id={`${id}Zip`}
              placeholder="Zip Code"
              readOnly
              // required
              value={postalCode || (destination && destination.address && destination.address.postalCode) || ''}
              onChange={event => { this.withDestination(onPostalCodeChanged, event.target.value) }}
            />
          </FormGroup>
          <FormGroup className="form-group-default readonly">
            <label>Name</label>
            <input
              type="text"
              name="destinationName"
              id={`${id}DestinationName`}
              data-test-id={`${id}DestinationName`}
              placeholder="Name"
              className="form-control"
              value={name || (destination && destination.name) || ''}
              readOnly={readOnly}
              onChange={event => this.withDestination(onDestinationNameChanged, event.target.value)}
            />
          </FormGroup>
        </Col>
        <Col sm={4}>
          <FormGroup className="form-group-default readonly">
            <label>Zone</label>
            <input
              type="text"
              name="zone"
              id={`${id}zone`}
              data-test-id={`${id}zone`}
              placeholder="Zone"
              className="form-control"
              value={zone || (destination && destination.zone) || ''}
              readOnly={readOnly}
              onChange={event => this.withDestination(onZoneChanged, event.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>;
  }
}

const mapStateToProps = ({ destination }, { as }) => ({ destination: destination[as] });
const mapDispatchToProps = { ...actions };

Destination = connect(
  mapStateToProps,
  mapDispatchToProps
)(Destination);

export default Destination;