// const string for actions in uppercase
const METRC_LICENSE_NEW = 'METRC_LICENSE_NEW';
const METRC_LICENSE_CLEAR = 'METRC_LICENSE_CLEAR';
const METRC_LICENSE_DISABLE = 'METRC_LICENSE_DISABLE';
const METRC_LICENSE_NUMBER = 'METRC_LICENSE_NUMBER';
const METRC_LICENSE_RECIPIENT = 'METRC_LICENSE_RECIPIENT';

// actions
export const actions = {
    onMetrcLicenseNew: () => ({ type: METRC_LICENSE_NEW }),
    onMetrcLicenseNumberChanged: (number) => ({ type: METRC_LICENSE_NUMBER, number }),
    onMetrcLicenseRecipientChanged: (recipientId) => ({ type: METRC_LICENSE_NUMBER, recipientId }),
    onMetrcLicenseClear: () => ({ type: METRC_LICENSE_CLEAR }),
};

// initial state
const initialState = {
    number: '',
    disabled: false,
    recipientId: '',
};

// action handlers
const actionHandlers = {
    [METRC_LICENSE_NEW]: (state) => ({ ...state, ...initialState }),
    [METRC_LICENSE_DISABLE]: (state, { disabled }) => ({ ...state, disabled }),
    [METRC_LICENSE_NUMBER]: (state, { number }) => ({ ...state, number }),
    [METRC_LICENSE_RECIPIENT]: (state, { recipientId }) => ({ ...state, recipientId }),
    [METRC_LICENSE_CLEAR]: (state) => ({ ...state, ...initialState }),
};

export const reducer = (state = initialState, action) => {
    const handler = actionHandlers[action.type]
    return handler ? handler(state, action) : state
};
