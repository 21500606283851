import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, Input } from 'reactstrap';

class Checkbox extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    checked: PropTypes.bool,
    readOnly: PropTypes.bool, //had to use disabled, readOnly was not working as expected
    onChange: PropTypes.func
  };

  render() {
    const { label, value, checked, onChange, id='checkbox', readOnly } = this.props;
    return <div className="checkbox">
      <Input type="checkbox" id={id} value={value} checked={checked} onChange={onChange} disabled={readOnly}/>
      <Label for={id}>{label}</Label>
    </div>
  }
}

export default Checkbox;
