import React from 'react';
import { CustomInput, Table } from 'reactstrap';
import * as collections from 'constants/collections';
import { NotificationManager, NotificationPosition } from 'components/Layout';

const DestinationTableList = (props) => {
    const {
        destinations,
        mraLicenses,
        firestore,
    } = props;
    const getMRALicenses = destinationId => {
        const licenses = mraLicenses
            .filter(l => l.destinationId === destinationId)
            .map(l => l.number);

        return licenses.join(', ');
    }

    const deleteAddress = async (address) => {
        if (window.confirm('Please confirm to delete this Customer\'s address')) {
            try {
                /*
                check if there are no tasks associated with recipient's address
                make two queries because firebase compounding queries have limited support for OR operator
                https://firebase.google.com/docs/firestore/query-data/queries#compound_queries
                */
                const hasDestinationResult = await firestore.collection(collections.TASK).where("destinationId", "==", address.id).get();
                const hasOriginResult = await firestore.collection(collections.TASK).where("originId", "==", address.id).get();

                // address does not have an order associated
                if (hasDestinationResult.empty && hasOriginResult.empty) {
                    await firestore
                        .collection(collections.DESTINATION)
                        .doc(address.id)
                        .delete();

                    const associatedLicenses = mraLicenses
                        .filter(l => l.destinationId === address.id);

                    if (associatedLicenses.length) {
                        associatedLicenses.forEach(async license => {
                        await firestore
                            .collection(collections.MRA_LICENSE)
                            .doc(license.id)
                            .delete();
                        });
                    }
                } else {
                    // soft delete
                    await firestore
                        .collection(collections.DESTINATION)
                        .doc(address.id)
                        .update({
                        disabled: true
                        });
                }
                NotificationManager.success('Customer\'s address successfully deleted', null, NotificationPosition.TOP_LEFT);
            } catch (error) {
                NotificationManager.error('An error occurred trying to delete the Customer\'s address.', null, NotificationPosition.TOP_LEFT);
            }
        }
    }

    const handleChange = (event) => {
        if (window.confirm("Please confirm to " + (event.target.checked ? "enable" : "disable") + " this item ?")) {
            firestore
                .collection(collections.DESTINATION)
                .doc(event.target.id)
                .update({
                    active: event.target.checked
                })
                .then(() => { });
        } else {
            event.target.checked = !event.target.checked;
        }
    }

    return (
    <div className="row">
        <Table responsive>
        <thead>
            <tr>
            <th>#</th>
            <th>Zip Code</th>
            <th>Licensing</th>
            <th>Name</th>
            <th>Actions</th>
            <th />
            </tr>
        </thead>
        <tbody>
            {destinations.map((item, idx) => (
            <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{item.address.postalCode || '-'}</td>
                <td>{getMRALicenses(item.id)}</td>
                <td>{item.address.line1}</td>
                <td>
                <CustomInput
                    type="switch"
                    id={item.id}
                    name={item.id}
                    defaultChecked={item.active}
                    onChange={handleChange}
                />
                </td>
                <td>
                <button
                    className={"btn btn-link"}
                    href="#"
                    onClick={() => deleteAddress(item)}
                >
                    <i className="fa fa-trash" />
                </button>
                </td>
            </tr>
            ))}
        </tbody>
        </Table>
    </div>
    );
}

export default DestinationTableList;
