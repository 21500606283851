import React, { Component, Fragment } from 'react';
import { NotificationContainer } from '.';
import { SearchOverlay } from '../Search';
import ProgressBar from '../ProgressBar';

class Layout extends Component {
  render() {
    const { children, isProgressBarVisible } = this.props;
    return <Fragment>
      {children}
      <NotificationContainer />
      <ProgressBar visible={isProgressBarVisible} />
      <SearchOverlay />
    </Fragment>;
  }
}

export default Layout;
