// const string for actions in uppercase
const APPP_SEARCH_OVERLAY_VISIBILITY = 'APPP_SEARCH_OVERLAY_VISIBILITY';

// actions
export const actions = {
  setSearchOverlayVisibility: (visibility) => ({ type: APPP_SEARCH_OVERLAY_VISIBILITY, visibility }),
};

// initial state
const initialState = {
  isSearchOverlayVisible: false,
};

// action handlers
const actionHandlers = {
  [APPP_SEARCH_OVERLAY_VISIBILITY]: (state, { visibility }) => ({ ...state, isSearchOverlayVisible: visibility }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
