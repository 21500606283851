import { auth } from "../firebase";

const defaultHeaders = {
  headers: { 'Content-Type': 'application/json' },
};

const authHeaders = async () => {
  const token = await auth.currentUser.getIdToken();
  return {
    ...defaultHeaders.headers,
    'Authorization': `Bearer ${token}`
  };
};

const fetchData = async (url, method, needAuth = false, body = null,  responseType = 'json') => {
  const headers =  needAuth ? await authHeaders() : defaultHeaders.headers;
  const config = {
    method,
    headers
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(url, config);

  switch (responseType) {
    case 'blob':
      return response.blob();
    case 'json':
    default:
      return response.json();
  }
};

const get = async (url) => fetchData(url, 'GET');
const getAuth = async (url) => fetchData(url, 'GET', true);
const postAuth = async (url, body) => fetchData(url, 'POST', true, body);
const postAuthBlob = async (url, body) => fetchData(url, 'POST', true, body, 'blob');

export {
  authHeaders,
  get,
  getAuth,
  postAuth,
  postAuthBlob
}
