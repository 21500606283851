import { combineReducers } from 'redux';

import firebase from './firebase';
import firestore from './firestore';

import { reducer as login } from './login';
import { reducer as reset } from './resetPassword';
import { reducer as fetching } from './fetching';
import { reducer as profile } from './profile';
import { reducer as task } from './task';
import { reducer as recipient } from './recipient';
import { reducer as destination } from './destination';
import { reducer as taskDetail } from './task-detail';
import { reducer as invoiceDetail } from './invoice-detail';
import { reducer as account } from './account';
import { reducer as setting } from './settings';
import { reducer as sms } from './sms';
import { reducer as vehicle } from './vehicle';
import { reducer as appState } from './appState';
import { reducer as terminal } from './terminal';
import { reducer as recaptcha } from './recaptcha';
import { reducer as brand } from './brand';
import { reducer as mraLicense } from './mraLicense';
import { reducer as orderInstructions } from './instructions';
import { reducer as cashOrder } from './cashOrder';


const rootReducer = combineReducers({
  firebase,
  firestore,
  login,
  reset,
  profile,
  fetching,
  task,
  destination,
  recipient,
  taskDetail,
  invoiceDetail,
  account,
  setting,
  sms,
  vehicle,
  appState,
  terminal,
  recaptcha,
  brand,
  mraLicense,
  orderInstructions,
  cashOrder,
});

export default rootReducer;
