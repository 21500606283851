import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from "uuid";

import Select from "components/Select";
import { api } from "config";
import { getLine1FromAddress } from "utils";

class Address extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    state: PropTypes.string,
    country: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.string,
  };

  static defaultProps = {
    placeholder: "Enter an address",
    readOnly: false,
    state: '',
    country: '',
  }

  render() {
    const self = this;
    const { id, state, country, value } = this.props;
    return <Select
        id={id}
        placeholder={(!value && this.props.placeholder) || null}
        readOnly={this.props.readOnly}
        value={value}
        options={value && [{ value, label: value }]}
        onChange={this.props.onChange}

        onOpen={() => {
          self.googlePlacesSessionToken = uuidv4();
        }}
        minimumInputLength={1}
        ajax={{
          url: api.searchPlaces,
          dataType: "json",
          delay: 250,
          data: function (params) {
            return {
              input: `${params.term}${state && `, ${state}`}${country && `, ${country}`}`,
              sessiontoken: self.googlePlacesSessionToken
            };
          },
          processResults: function (data) {
            return {
              results:
                (data &&
                  data.predictions.map(p => ({
                    ...p,
                    id: p.description,
                    // Let's remove the state/country from the address
                    line1: getLine1FromAddress(p.description)
                  }))) ||
                []
            };
          }
        }}
        templateResult={place => {
          if (place.loading) {
            return place.text;
          }

          return (
            '<div class="boltbox-suggestion">' +
              '<div class="boltbox-suggestion-title">' + place.structured_formatting.main_text + '</div>' +
              '<div class="boltbox-suggestion-detail">' + place.structured_formatting.secondary_text + '</div>' +
            '</div>'
          );
        }}
        templateSelection={place => {
          return place.line1 || place.text;
        }}
      ></Select>
  }
}

export default Address;
