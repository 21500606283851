const TASK_INSTRUCTIONS_CHANGED = 'TASK_INSTRUCTIONS_CHANGED';
const TASK_LOADING_INSTRUCTIONS_CHANGED = 'TASK_LOADING_INSTRUCTIONS_CHANGED';

export const actions = {
  onLoadingInstructionsChanged: loadingInstructions => ({
    type: TASK_LOADING_INSTRUCTIONS_CHANGED,
    loadingInstructions,
  }),
  onTaskInstrunctionsChanged: orderInstructions => ({
    type: TASK_INSTRUCTIONS_CHANGED,
    orderInstructions,
  }),
};
const initialState = {
  orderInstructions: null,
  loadingInstructions: false,
};

// action handlers
const actionHandlers = {
  [TASK_INSTRUCTIONS_CHANGED]: (state, { orderInstructions }) => ({
    ...state,
    orderInstructions,
  }),
  [TASK_LOADING_INSTRUCTIONS_CHANGED]: (state, { loadingInstructions }) => ({
    ...state,
    loadingInstructions,
  }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
