import React from 'react';
import { compose } from 'redux';
import { useSelector } from "react-redux";
import { withFirebase, withFirestore, firestoreConnect } from 'react-redux-firebase';
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalHeader
} from 'reactstrap';
import classnames from 'classnames';
import { Drivers, Dispatchers } from 'components/Users';
import * as collections from 'constants/collections';
import Customers from './Customers/Customers';
import Vehicles from './Vehicles';
import Terminals from './Terminals';
import GeneralSettings from './GeneralSettings';
import Brands from './Brands';
import Loading from 'components/Loading';

const Settings = (props) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const { users, recipients } = useSelector(
    ({
      firestore: {
        ordered: { users, recipients },
      },
    }) => ({ users, recipients })
  );

  const { isOpen, toggle, error, success } = props;

  const handleActiveTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const updateData = React.useCallback((delay = false) => {
    if (!delay) {
      props.firestore.get(collections.USER);
      return;
    }

    // add some delay so we update user collections after firebase function create user
    setTimeout(() => props.firestore.get(collections.USER), 20000);

  }, [props.firestore])

  const tabs = React.useMemo(() => {
    return [
      {
        title: 'General Settings',
        subtitle: 'Manage your teams and preferences',
        component: <GeneralSettings />,
      },
      {
        title: 'Drivers',
        subtitle: 'Manage drivers and the driver app',
        component: <Drivers currentUser={props.currentUser} users={users} updateData={updateData} />,
      },
      {
        title: 'Brands',
        subtitle: 'Manage Brands',
        component: <Brands />,
      },
      {
        title: 'Employees',
        subtitle: 'On-board and manage dispatchers',
        component: <Dispatchers currentUser={props.currentUser} users={users} updateData={updateData} />,
      },
      {
        title: 'Customers',
        subtitle: 'Manage customer settings',
        component: <Customers users={users}/>,
      },
      {
        title: 'Vehicles',
        subtitle: 'Vehicle maintenance',
        component: <Vehicles />,
      },
      {
        title: 'Terminals',
        subtitle: 'Manage vehicle terminals',
        component: <Terminals />,
      },
      // {title: 'Plans and Billing', subtitle: 'Manage your subscription'},
      // {title: 'API & WebHooks', subtitle: 'Integrate with other services'},
    ]
  }, [props.currentUser, users, updateData]);

  const getTabContent = (tabIndex) => {
    return tabs[tabIndex].component;
  }

  if (!users?.length || !recipients?.length){
    return (<Loading />);
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={props.className} zIndex="1060">
      <ModalHeader toggle={toggle}>Settings</ModalHeader>
      <ModalBody>
        <div className="card card-transparent flex-row settings-content">
          <Nav tabs className="nav nav-tabs nav-tabs-simple nav-tabs-left bg-white settings">
            {tabs.map((item, index) =>
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: activeTab === index })}
                  onClick={() => handleActiveTab(index)}
                >
                  <p>{item.title}</p>
                  <p className={'subtitle'}>{item.subtitle}</p>
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Container>
                <Row>
                  <Col xs={12}>
                    {getTabContent(activeTab)}
                  </Col>
                </Row>
              </Container>
            </TabPane>
          </TabContent>
          <span style={{ color: 'red' }}>{!success && error && error.message}</span>
          <span style={{ color: 'black' }}>{success && success.message}</span>
        </div>
      </ModalBody>
    </Modal>
  );
}

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  firestoreConnect([
    {
      collection: collections.TERMINAL,
      orderBy: ['name', 'asc'],
      where: ['disabled', '==', false],
    },
    {
      collection: collections.RECIPIENT
    }
  ]),
)(Settings);
export default FirestoreConnected;
