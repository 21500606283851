export const ORDER_EVENT_CREATION = 'creation';
export const ORDER_EVENT_NOTIFICATION_STARTED = 'notification_started';
export const ORDER_EVENT_PICKUP = 'pickup';
export const ORDER_EVENT_DEPARTURE = 'departure';
export const ORDER_EVENT_NOTIFICATION_ARRIVING = 'notification_arriving';
export const ORDER_EVENT_ARRIVAL = 'arrival';
export const ORDER_EVENT_SIGNATURE = 'signature';
export const ORDER_EVENT_COMPLETION = 'completion';
export const ORDER_EVENT_INVOICE_CREATION = 'invoice_creation';
export const ORDER_EVENT_CANCELED = 'canceled';

export const EVENT_TYPES = new Map([
    [ORDER_EVENT_CREATION, 'Order created'],
    [ORDER_EVENT_NOTIFICATION_STARTED, 'Customer notified about task'],
    [ORDER_EVENT_PICKUP, 'Payload pickup'],
    [ORDER_EVENT_DEPARTURE, 'Driver departed'],
    [ORDER_EVENT_NOTIFICATION_ARRIVING, 'Customer notified about arriving'],
    [ORDER_EVENT_ARRIVAL, 'Driver arrived'],
    [ORDER_EVENT_SIGNATURE, 'Signature collected'],
    [ORDER_EVENT_COMPLETION, 'Order completed'],
    [ORDER_EVENT_INVOICE_CREATION, 'Invoice created'],
    [ORDER_EVENT_CANCELED, 'Order canceled'],
  ]
);