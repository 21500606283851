import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import 'react-phone-number-input/style.css';
import { Destination } from 'components/Destination';
import * as destinations from 'constants/destinations';
import * as collections from 'constants/collections';
import { NotificationManager, NotificationPosition } from 'components/Layout';

class DestinationForm extends Component {
  static propTypes = { 
    destination: PropTypes.object,
    mraLicense: PropTypes.object,
    onMetrcLicenseNumberChanged: PropTypes.func,
    showList: PropTypes.func,
    setLoading: PropTypes.func,
    recipient: PropTypes.object,
    firestore: PropTypes.any,
  };

  saveDestination = async () => {
    const {
      recipient,
      firestore,
      clear,
      destination: { [destinations.RECIPIENT_ADDRESS]: destination },
      mraLicense,
      mraLicenses,
      onMetrcLicenseClear,
      setLoading,
    } = this.props;
    try {
      if (
        destination.address.state === '' ||
        destination.address.country === '' ||
        destination.address.formatted === '' ||
        destination.address.line1 === '' ||
        mraLicense.number === ''
      ) {
        NotificationManager.error("A valid address and Licensing is required!", null, NotificationPosition.TOP_LEFT);
        return;
      }

      const licenseExists = mraLicenses && mraLicenses.find(l => l.number === mraLicense.number);

      if (licenseExists) {
        NotificationManager.error('There is already a Licensing registered with this number!', null, NotificationPosition.TOP_LEFT);
        return;
      }

      const data = {
        ...destination,
        owner: recipient.id,
        active: true,
      };

      setLoading(true);

      const newDestination = await firestore.add(collections.DESTINATION, data);

      if (newDestination) {
        const licenseData = {
          ...mraLicense,
          recipientId: recipient.id,
          destinationId: newDestination.id,
          owner: recipient.id,
        };

        await firestore.add(collections.MRA_LICENSE, licenseData);
        onMetrcLicenseClear();
      }

      //al good
      this.setState({ destinationForErrors: false });
      // clear destination
      clear(destinations.RECIPIENT_ADDRESS);

    } catch {
      NotificationManager.error(
        'An error occurred trying to save the address.',
        null,
        NotificationPosition.TOP_LEFT
      );

    } finally {
      setLoading(false);
    }
  };

  render() {
    const {
      destination,
      mraLicense,
      onMetrcLicenseNumberChanged,
      showList,
    } = this.props;

    return (
      <div className="row">
        <div className="col-xs-12 col-md-12 p-t-15">
          <h5>Addresses</h5>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.saveDestination();
            }} >
            <div className="mt-2">
              <input
                type="text"
                name="metrc"
                placeholder="Licensing #"
                className="form-control"
                value={mraLicense.number || ''}
                onChange={event => {
                  onMetrcLicenseNumberChanged(event.target.value);
                }}
              />
            </div>
            <div className="mt-2">
              <Destination
                id="new-address"
                as={destinations.RECIPIENT_ADDRESS}
                readOnly={true} />
            </div>
            <Button color="primary" type="submit">
              {destination && destination.id ? "Update" : "Add"}
            </Button>&nbsp;
            <Button
              type="button"
              color="secondary"
              onClick={() => showList()}
            >
              Cancel
            </Button>
            <br />
            <br />
          </form>
        </div>
      </div>
    );
  }
}

export default DestinationForm;
