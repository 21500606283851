import { api } from 'config';
import { postAuth } from "utils/apiHelpers";

const SEND_SMS_STATUS = 'SEND_SMS_STATUS';

// actions
export const actions = {
  sendSMS: (to, message) => (dispatch) => {
    return postAuth(api.sendSMS, { to, message })
      .then(jsonResponse => {
        const sent = jsonResponse.status === 'sent';
        dispatch({ type: SEND_SMS_STATUS, sent });
        return sent;
      }).catch(() => false)
  }
};

// initial state
const initialState = {
  sent: false
};

// action handlers
const actionHandlers = {
  [SEND_SMS_STATUS]: (state, { sent }) => ({ ...state, sent }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
