export const CONVERSATION = 'conversations';
export const DESTINATION = 'destinations';
export const MESSAGE = 'messages';
export const MONITORING = 'monitoring';
export const RECIPIENT = 'recipients';
export const ORDER = 'orders';
export const TASK = 'tasks';
export const USER = 'users';
export const NEW_USERS = 'newUsers';
export const TRACK = 'tracks';
export const SETTING = 'settings';
export const NOTIFICATION = 'notifications';
export const VEHICLE = 'vehicles';
export const TERMINAL = 'terminals';
export const BRAND = 'brands';
export const ORDER_STATS = 'orderStats';
export const ORDER_INSTRUCTIONS = 'orderInstructions';
export const DRIVER_STATS = 'driverStats';
export const MRA_LICENSE = 'mraLicenses';
export const ORDER_REQUESTS = 'orderRequests';
export const ACCOUNT_REQUESTS = 'accountRequests';
export const CASH_ORDERS = 'cashOrders';

