import React from 'react';
import * as Sentry from '@sentry/browser';

const errorBoundary = (Component) => {
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(err) {
      if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(err);
      }
    }

    static getDerivedStateFromError() {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }

    render() {
      if (this.state.hasError) {
        return (
          <div className="d-flex justify-content-center full-height full-width align-items-center">
            <div className="error-container text-center">
              <span className="error-icon"><i className="fa fa-frown-o"></i></span>
              <h4 className="mt-4 semi-bold">We're sorry! An unexpected error ocurred but we're working to fix the problem.</h4>
              <h6 className="mt-4 semi-bold">Contact support if you need more information.</h6>
              <h6 className="mt-4 semi-bold">Thanks for your patience.</h6>
            </div>
          </div>
        );
      }

      return <Component {...this.props} />;
    }
  }

  return ErrorBoundary;
};

export default errorBoundary;
