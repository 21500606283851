// const string for actions in uppercase
const TERMINAL_NEW = 'TERMINAL_NEW';
const TERMINAL_EDIT = 'TERMINAL_EDIT';
const TERMINAL_TAX_PERCENTAGE_CHANGED = 'TERMINAL_TAX_PERCENTAGE_CHANGED';

// actions
export const actions = {
  onTerminalNew: () => ({ type: TERMINAL_NEW }),
  onTerminalEdit: (data) => ({ type: TERMINAL_EDIT, data }),
  onTerminalTaxPercentageChanged: (taxPercentage) => ({ type: TERMINAL_TAX_PERCENTAGE_CHANGED, taxPercentage: parseFloat(taxPercentage) }),
};

// initial state
const initialState = {
  id: null,
  taxPercentage: null,
};

// action handlers
const actionHandlers = {
  [TERMINAL_NEW]: (state) => ({ ...state, ...initialState }),
  [TERMINAL_EDIT]: (state, { data }) => ({ ...state, ...data }),
  [TERMINAL_TAX_PERCENTAGE_CHANGED]: (state, { taxPercentage }) => ({ ...state, taxPercentage }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
