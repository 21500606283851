import { collator } from 'utils';

const sanitizeText = (val = '') => val.toLowerCase().trim();

export const filterListByText = (list, filterText) => {
    if (!filterText) return list;
    if (!list) return [];
    const filter = sanitizeText(filterText).trim();
    return list.filter(item => {
        if (
            (sanitizeText(item.phone).replaceAll(' ', '')
                .indexOf(filter.replaceAll(' ', '')) !== -1)
            || sanitizeText(item.email).indexOf(filter) !== -1
            || sanitizeText(item.name).indexOf(filter) !== -1
            || sanitizeText(item.companyName).indexOf(filter) !== -1
          ) {
          return item;
        }

        return null;
    });
};

export const getCustomersWithRecipients = (users = [], recipients = [], filterText = '') =>  {
    const customerUsers = users.map(user => {
        const userRecipient = recipients.find(recipient => recipient.id === user.recipientId);
        if (userRecipient) {
          return {
            ...userRecipient,
            companyName: userRecipient.name,
            ...user,
            id: user.uid,
          };
        }
        return null;
    }).filter(item => item);

    const uniqueCustomerUsers = [...new Map(customerUsers.map(item => [item.email, item])).values()];

    const filteredList = filterListByText(uniqueCustomerUsers, filterText) ?? [];
    return filteredList
      .sort((a, b) => collator.compare(a.name, b.name))
      .map((item, idx) => ({ idx: (idx + 1), ...item }));
}
