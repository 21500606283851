import React from 'react';
import DataTable from 'react-data-table-component';
import 'react-phone-number-input/style.css';
import * as collections from 'constants/collections';
import { NotificationManager, NotificationPosition } from 'components/Layout';
import firebase from 'firebase';
import { Tooltip } from '@mui/material';
import { environment } from 'config';

const CustomerList = (props) => {
    const {
        showRecipient,
        firestore,
        setLoading,
        showList,
        updateCustomerList,
        clear,
        customerRecipients,
    } = props;

    const disableCustomer = (currentCustomer) => {
        const action = currentCustomer.disabled ? "enable" : "disable";
        if (window.confirm(`Please confirm to ${action} this customer`)) {
            firestore
                .collection(collections.USER)
                .doc(currentCustomer.uid)
                .update({
                    disabled: !currentCustomer.disabled,
                })
                .then(() => {
                    NotificationManager.success(`Customer successfully ${action}d`);
                    clear();
                    showList();
                    updateCustomerList();
                });
        }
    }
    
    const sendPasswordRecoveryEmail = async  (customer) => {
        if (window.confirm(`Please confirm to send the password recovery email to ${customer.name}`)) {
            setLoading(true);
            const email = customer.email;
            const actionCodeSettings = {
                url: environment.customerPortalUrl,
            };
            await firebase.auth()
                .sendPasswordResetEmail(email, actionCodeSettings)
                .then(() => {
                    NotificationManager.success('Password recovery email sent', null, NotificationPosition.TOP_LEFT);
                })
                .catch(() => {
                    NotificationManager.error('Error sending password recovery email', null, NotificationPosition.TOP_LEFT);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const onRowClicked = recipient => {
        showRecipient(recipient);
    }

    const columns = [
        {
            name: '#',
            maxWidth: '5%',
            center: true,
            selector: 'idx',
            sortable: true,
        },
        {
            name: 'Name',
            left: true,
            selector: 'name',
            sortable: true
        },
        {
            name: 'Phone',
            left: true,
            selector: 'phone',
            sortable: true
        },
        {
            name: 'Company',
            left: true,
            selector: 'companyName',
            sortable: true
        },
        {
            name: 'Actions',
            left: true,
            button: true,
            cell: customer => {
                return <>
                    <Tooltip title={customer.disabled ? "Enable customer" : "Disable customer"}>
                        <button
                            className="btn btn-link"
                            href="#"
                            onClick={() => disableCustomer(customer)}>
                            {customer.disabled ? <i className="fa fa-check" /> : <i className="fa fa-ban" />}
                        </button>
                    </Tooltip>
                    <Tooltip title=" Send password recovery email">
                        <button
                            className="btn btn-link ml-2"
                            href="#"
                            onClick={() =>sendPasswordRecoveryEmail(customer)}>
                            <i className="fa fa-envelope-o" />
                        </button>
                    </Tooltip>
                </>
            }
        },
    ];
  
    return (
        <DataTable
            columns={columns}
            dense
            data={customerRecipients}
            highlightOnHover
            noHeader
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50]}
            responsive
            pointerOnHover
            onRowClicked={onRowClicked}
        />
    )
}

export default CustomerList;
