// *working on importing material UI*
//import * as React from 'react';
//import { Router, Route, Switch, Link } from 'react-router-dom';

// layouts -------------------------------------------------------------
//import DashboardLayout from '../layouts/dashboard' // Layout from Minimalist MUI Kit
// END Imports ---------------------------------------------------------
// * END of material UI imports*

export const homePath = '/';
export const loginPath = '/login';
// page paths ----------------------------------------------------------
export const userPath = '/user'; //Adding User Material UI Template
export const drawererPath = '/drawer'; //Adding Drawer Test Page
export const firebasePath = '/firebasetest'; //Firebase consumption test page
// test pages END  -----------------------------------------------------
export const ordersPath = '/orders';
export const orderRequestsPath = '/requests';
export const orderDraftsPath = '/drafts';
export const cashOrdersPath = '/cash-orders';
export const editCashOrderPath = `${cashOrdersPath}/:id/edit`;
export const cashOrderPathForEdit = id => `${cashOrdersPath}/${id}/edit`;
export const newOrderPath = `${ordersPath}/new`;
export const viewOrderPath = `${ordersPath}/:id`;
export const editOrderPath = `${ordersPath}/:id/edit`;
export const orderPathForView = id => `${ordersPath}/${id}`;
export const orderPathForEdit = id => `${ordersPath}/${id}/edit`;
export const newOrderWithRequest = id => `${newOrderPath}?request=${id}`;
export const reportsPath = `/reports`;
export const settingsPath = `/settings`;
export const accountRequestsPath = `${settingsPath}/account-requests`;

// ----------------------------------------------------------------------

