import React from 'react';

const ResourceNotFound = ({ message, code = '404' }) => {
  return (
    <div className="d-flex justify-content-center full-height full-width align-items-center">
      <div className="error-container text-center">
        <span className="error-icon"><i className="fa fa-frown-o"></i></span>
        <h1 className="mt-4 error-number">{code}</h1>
        <h4 className="mt-4 semi-bold">{message}</h4>
      </div>
    </div>
  );
}

export default ResourceNotFound;
