// const string for actions in uppercase
const TASK_TERMINAL_CHANGED = 'TASK_TERMINAL_CHANGED';
const TASK_BRAND_CHANGED = 'TASK_BRAND_CHANGED';
const TASK_FILTERED_BRANDS_CHANGED = 'TASK_FILTERED_BRANDS_CHANGED';
const TASK_DRIVER_CHANGED = 'TASK_DRIVER_CHANGED';
const TASK_ASSISTANT_CHANGED = 'TASK_ASSISTANT_CHANGED';
const TASK_VEHICLE_CHANGED = 'TASK_VEHICLE_CHANGED';
const TASK_RECIPIENT_CHANGED = 'TASK_RECIPIENT_CHANGED';
const TASK_DESTINATION_CHANGED = 'TASK_DESTINATION_CHANGED';
const TASK_RATING_CHANGED = 'TASK_RATING_CHANGED';
const TASK_RECIPIENT_INFO_CHANGED = 'TASK_RECIPIENT_INFO_CHANGED';
const TASK_NEW_ITEM = 'TASK_NEW_ITEM';
const TASK_REMOVE_ITEM = 'TASK_REMOVE_ITEM';
const TASK_TYPE_CHANGED = 'TASK_TYPE_CHANGED';
const TASK_FORM_CLEAR = 'TASK_FORM_CLEAR';
const TASK_FORM_ERROR = 'TASK_FORM_ERROR';
const TASK_FORM_SUCCESS = 'TASK_FORM_SUCCESS';
const TASK_SENDER_CHANGED = 'TASK_SENDER_CHANGED';
const TASK_ORIGIN_CHANGED = 'TASK_ORIGIN_CHANGED';
const TASK_ESTIMATED_PICKUP_DATE = 'TASK_ESTIMATED_PICKUP_DATE';
const TASK_ESTIMATED_PICKUP_TIME = 'TASK_ESTIMATED_PICKUP_TIME';
const TASK_ESTIMATED_DELIVERY_DATE = 'TASK_ESTIMATED_DELIVERY_DATE';
const TASK_ESTIMATED_DELIVERY_TIME = 'TASK_ESTIMATED_DELIVERY_TIME';
const TASK_REQUIREMENT = 'TASK_REQUIREMENT';
const TASK_PICKUP_REQUIREMENT = 'TASK_PICKUP_REQUIREMENT';
const TASK_DELIVERY_REQUIREMENT = 'TASK_DELIVERY_REQUIREMENT';
const TASK_SIMULATE = 'TASK_SIMULATE';
const TASK_NOTES = 'TASK_NOTES';
const TASK_ORDER_NUMBER = 'TASK_ORDER_NUMBER';
const TASK_FUEL_SURCHARGE_CHANGED = 'TASK_FUEL_SURCHARGE_CHANGED';
const TASK_STARTING_POINT_CHANGED = 'TASK_STARTING_POINT_CHANGED';
const TASK_NEW_INVOICE_ITEM = 'TASK_NEW_INVOICE_ITEM';
const TASK_REMOVE_INVOICE_ITEM = 'TASK_REMOVE_INVOICE_ITEM';

const TASK_WAYPOINTS_SET = 'TASK_WAYPOINTS_SET';
const TASK_WAYPOINTS_ADD = 'TASK_WAYPOINTS_ADD';
const TASK_WAYPOINTS_CLEAR = 'TASK_WAYPOINTS_CLEAR';
const TASK_ORIGIN_CHANGE = 'TASK_ORIGIN_CHANGE';
const TASK_RECIPIENT_LICENSE_CHANGED = 'TASK_RECIPIENT_LICENSE_CHANGED';
const TASK_SENDER_LICENSE_CHANGED = 'TASK_SENDER_LICENSE_CHANGED';
const TASK_TASK_PERCENTAGE_CHANGED = 'TASK_TASK_PERCENTAGE_CHANGED';

const TASK_UNDO = 'TASK_UNDO';
const TASK_REDO = 'TASK_REDO';
const TASK_NEW = 'TASK_NEW';
const TASK_EDIT = 'TASK_EDIT';
const TASK_DRAFT = 'TASK_DRAFT';


// actions
export const actions = {
  onNewTask: () => ({ type: TASK_NEW }),
  onEditTask: (data) => ({ type: TASK_EDIT, data }),
  onTerminalChanged: (terminal) => ({ type: TASK_TERMINAL_CHANGED, terminal }),
  onBrandChanged: (brand) => ({ type: TASK_BRAND_CHANGED, brand }),
  onFilteredBrandChanged: (brand) => ({ type: TASK_FILTERED_BRANDS_CHANGED, brand }),
  onDriverChanged: (driver) => ({ type: TASK_DRIVER_CHANGED, driver }),
  onAssistantChanged: (assistant) => ({ type: TASK_ASSISTANT_CHANGED, assistant }),
  onVehicleChanged: (vehicle) => ({ type: TASK_VEHICLE_CHANGED, vehicle }),
  onRecipientChanged: (recipient) => ({ type: TASK_RECIPIENT_CHANGED, recipient }),
  onDestinationChanged: (destination) => ({ type: TASK_DESTINATION_CHANGED, destination }),
  onRatingChanged: (rating) => ({ type: TASK_RATING_CHANGED, rating }),
  onTypeChanged: (taskType) => ({ type: TASK_TYPE_CHANGED, taskType }),
  onOrderNumberChanged: (orderNumber) => ({ type: TASK_ORDER_NUMBER, orderNumber }),
  onNotesChanged: (taskType, notes) => ({ type: TASK_NOTES, taskType, notes }),
  onRecipientInfoChanged: (on, recipient) => ({ type: TASK_RECIPIENT_INFO_CHANGED, on, recipient }),
  onSenderChanged: (sender) => ({ type: TASK_SENDER_CHANGED, sender }),
  onOriginChanged: (origin) => ({ type: TASK_ORIGIN_CHANGED, origin }),
  onEstimatedPickupDate: (date) => ({ type: TASK_ESTIMATED_PICKUP_DATE, date }),
  onEstimatedPickupTime: (time) => ({ type: TASK_ESTIMATED_PICKUP_TIME, time }),
  onEstimatedDeliveryDate: (date) => ({ type: TASK_ESTIMATED_DELIVERY_DATE, date }),
  onEstimatedDeliveryTime: (time) => ({ type: TASK_ESTIMATED_DELIVERY_TIME, time }),
  onRequirementChanged: (requirement, checked) => ({ type: TASK_REQUIREMENT, requirement, checked }),
  onPickupRequirementChanged: (requirement, checked) => ({ type: TASK_PICKUP_REQUIREMENT, requirement, checked }),
  onDeliveryRequirementChanged: (requirement, checked) => ({ type: TASK_DELIVERY_REQUIREMENT, requirement, checked }),
  onSimulateChanged: (simulation) => ({ type: TASK_SIMULATE, simulation }),
  onFuelSurchargeChanged: (fuelSurcharge) => ({ type: TASK_FUEL_SURCHARGE_CHANGED, fuelSurcharge: Math.abs(parseFloat(fuelSurcharge)) }),
  onTaxPercentageChanged: (taxPercentage) => ({ type: TASK_TASK_PERCENTAGE_CHANGED, taxPercentage: Math.abs(parseFloat(taxPercentage)) }),
  onStartingPointChanged: (startingPoint) => ({ type: TASK_STARTING_POINT_CHANGED, startingPoint }),
  onNewItem: (item) => ({ type: TASK_NEW_ITEM, item }),
  onRemoveItem: (index) => ({ type: TASK_REMOVE_ITEM, index }),
  onNewInvoiceItem: (item) => ({ type: TASK_NEW_INVOICE_ITEM, item }),
  onRemoveInvoiceItem: (index) => ({ type: TASK_REMOVE_INVOICE_ITEM, index }),
  onError: (error) => ({ type: TASK_FORM_ERROR, error }),
  onSuccess: (success) => ({ type: TASK_FORM_SUCCESS, success }),
  clearTask: () => ({ type: TASK_FORM_CLEAR }),

  // wayPoint [lon, lat]
  setWaypoints: (waypoints) => ({ type: TASK_WAYPOINTS_SET, waypoints }),
  addWaypoint: (waypoint) => ({ type: TASK_WAYPOINTS_ADD, waypoint }),
  clearWayPoints: () => ({ type: TASK_WAYPOINTS_CLEAR }),
  changeOrigin: (origin) => ({ type: TASK_ORIGIN_CHANGE, origin }),
  // This is create to support undo and redo of change origin and waypoints
  // insertion
  undo: () => ({ type: TASK_UNDO }),
  redo: () => ({ type: TASK_REDO }),

  setDraft: (data) => ({ type: TASK_DRAFT, data }),
  onRecipientLicenseChanged: (recipientLicense) => ({ type: TASK_RECIPIENT_LICENSE_CHANGED, recipientLicense }),
  onSenderLicenseChanged: (senderLicense) => ({ type: TASK_SENDER_LICENSE_CHANGED, senderLicense }),
};

const addUndoSupport = (state) => ({
  actions: {
    undo: [
      ...state.actions.undo,
      {
        origin: state.origin,
        waypoints: state.waypoints,
      }
    ],
    // Reset it
    redo: [],
  }
});

// initial state
const initialState = {
  driverId: null,
  assistantId: null,
  terminalId: null,
  vehicle: null,
  vehicleId: null,
  sender: null,
  origin: null,
  destination: null,
  estimatedPickup: {},
  estimatedDelivery: {},
  recipients: {},
  taskTypes: ['pickup', 'delivery'],
  requirements: {},
  pickupRequirements: {},
  deliveryRequirements: {},
  items: [],
  notes: "",
  pickupNotes: "",
  deliveryNotes: "",
  error: null,
  success: null,
  originId: null,
  recipientId: null,
  simulation: false,
  fuelSurcharge: null,
  taxPercentage: null,
  invoiceItems: [],
  senderId: null,
  destinationId: null,
  recipient: null,
  status: null,
  assistant: null,
  details: null,
  orderNumber: null,
  id: null,
  // For custom route
  waypoints: [],
  // Actions. This is for redo and undo
  actions: {
    undo: [],
    redo: [],
  },
  draft: false,
  recipientLicense: null,
  senderLicense: null,
  brand: null,
  filteredBrands: [],

  pickupTaskId: null,
  deliveryTaskId: null,
  pickupTaskStatus: null,
  deliveryTaskStatus: null,
  pickupTaksEvents: null,
  deliveryTaskEvents: null,
  cashChanges: [],
};

// action handlers
const actionHandlers = {
  [TASK_NEW]: (state) => ({ ...state, ...initialState }),
  [TASK_EDIT]: (state, { data }) => ({ ...state, ...data, draft: state.draft, taxPercentage: data.taxPercentage }),
  [TASK_TERMINAL_CHANGED]: (state, { terminal }) => ({ ...state, terminalId: terminal }),
  [TASK_DRIVER_CHANGED]: (state, { driver }) => ({ ...state, driverId: driver }),
  [TASK_BRAND_CHANGED]: (state, { brand }) => ({ ...state, brand: brand }),
  [TASK_FILTERED_BRANDS_CHANGED]: (state, { brand }) => ({ ...state, filteredBrands: brand }),
  [TASK_ASSISTANT_CHANGED]: (state, { assistant }) => ({ ...state, assistantId: assistant }),
  [TASK_VEHICLE_CHANGED]: (state, { vehicle }) => ({ ...state, vehicle }),
  [TASK_RECIPIENT_CHANGED]: (state, { recipient }) => ({ ...state, recipient }),
  [TASK_DESTINATION_CHANGED]: (state, { destination }) => ({
    ...state,
    destination,
    destinationId: destination?.id,
  }),
  [TASK_RATING_CHANGED]: (state, { rating }) => ({ ...state, rating }),
  [TASK_TYPE_CHANGED]: (state, { taskType }) => {
    const { taskTypes } = state;
    let newTaskTypes = taskTypes.includes(taskType) ? taskTypes.filter(type => type !== taskType) : [...taskTypes, taskType];
    return { ...state, taskTypes: newTaskTypes };
  },
  [TASK_PICKUP_REQUIREMENT]: (state, { requirement, checked }) => ({ ...state, pickupRequirements: { ...state.pickupRequirements, [requirement]: checked } }),
  [TASK_NOTES]: (state, { taskType, notes }) => ({ ...state, [`${taskType}Notes`]: notes }),
  [TASK_RECIPIENT_INFO_CHANGED]: (state, { on, recipient }) => ({
    ...state, recipients: { ...state.recipients, [on]: recipient }
  }),
  [TASK_SENDER_CHANGED]: (state, { sender }) => ({ ...state, sender }),
  [TASK_ORIGIN_CHANGED]: (state, { origin }) => ({ ...state, origin, originId: origin?.id }),
  [TASK_ESTIMATED_PICKUP_DATE]: (state, { date }) => ({ ...state, estimatedPickup: { ...state.estimatedPickup, date } }),
  [TASK_ESTIMATED_PICKUP_TIME]: (state, { time }) => ({ ...state, estimatedPickup: { ...state.estimatedPickup, time } }),
  [TASK_ESTIMATED_DELIVERY_DATE]: (state, { date }) => ({ ...state, estimatedDelivery: { ...state.estimatedDelivery, date } }),
  [TASK_ESTIMATED_DELIVERY_TIME]: (state, { time }) => ({ ...state, estimatedDelivery: { ...state.estimatedDelivery, time } }),
  [TASK_REQUIREMENT]: (state, { requirement, checked }) => ({ ...state, requirements: { ...state.requirements, [requirement]: checked } }),
  [TASK_PICKUP_REQUIREMENT]: (state, { requirement, checked }) => ({ ...state, pickupRequirements: { ...state.pickupRequirements, [requirement]: checked } }),
  [TASK_DELIVERY_REQUIREMENT]: (state, { requirement, checked }) => ({ ...state, deliveryRequirements: { ...state.deliveryRequirements, [requirement]: checked } }),
  [TASK_SIMULATE]: (state, { simulation }) => ({ ...state, simulation }),
  [TASK_FUEL_SURCHARGE_CHANGED]: (state, { fuelSurcharge }) => ({ ...state, fuelSurcharge }),
  [TASK_TASK_PERCENTAGE_CHANGED]: (state, { taxPercentage }) => ({ ...state, taxPercentage }),
  [TASK_STARTING_POINT_CHANGED]: (state, { startingPoint }) => ({ ...state, startingPoint }),

  [TASK_NEW_ITEM]: (state, { item }) => {
    const alreadyExist = state.items.find(i => i.metrcReferenceNumber === item.metrcReferenceNumber);
    if (!alreadyExist) {
      return { ...state, items: [...state.items, item] };
    }

    return state;
  },

  [TASK_REMOVE_ITEM]: (state, { index }) => {
    return {
      ...state, items: [
        ...state.items.slice(0, index),
        ...state.items.slice(index + 1)
      ]
    };
  },

  [TASK_NEW_INVOICE_ITEM]: (state, { item }) => ({ ...state, invoiceItems: [...state.invoiceItems, item] }),
  [TASK_REMOVE_INVOICE_ITEM]: (state, { index }) => {
    return {
      ...state, invoiceItems: [
        ...state.invoiceItems.slice(0, index),
        ...state.invoiceItems.slice(index + 1)
      ]
    };
  },
  [TASK_FORM_ERROR]: (state, { error }) => ({ ...state, error: error }),
  [TASK_FORM_SUCCESS]: (state, { success }) => ({ ...state, success: success }),
  [TASK_FORM_CLEAR]: (state) => ({ ...initialState, draft: state.draft }),

  [TASK_WAYPOINTS_SET]: (state, { waypoints }) => ({
    ...state,
    waypoints,
  }),
  [TASK_WAYPOINTS_ADD]: (state, { waypoint }) => ({
    ...state,
    waypoints: [...state.waypoints, waypoint],
    // Undo support
    ...addUndoSupport(state),
  }),
  [TASK_WAYPOINTS_CLEAR]: (state) => ({
    ...state,
    waypoints: [],
    // Undo support
    ...addUndoSupport(state),
  }),
  [TASK_ORIGIN_CHANGE]: (state, { origin }) => ({
    ...state,
    origin,
    // Undo support
    ...addUndoSupport(state),
  }),

  // Adding support to undo/redo
  [TASK_UNDO]: (state) => {
    const newState = { ...state };
    const last = newState.actions.undo.pop();
    const current = {
      origin: state.origin,
      waypoints: state.waypoints
    };
    newState.origin = last.origin;
    newState.waypoints = last.waypoints;
    newState.actions.redo = [
      ...newState.actions.redo,
      current,
    ];
    return newState;
  },
  [TASK_REDO]: (state) => {
    const newState = { ...state };
    const last = newState.actions.redo.pop();
    const current = {
      origin: state.origin,
      waypoints: state.waypoints
    };
    newState.origin = last.origin;
    newState.waypoints = last.waypoints;
    newState.actions.undo = [
      ...newState.actions.undo,
      current,
    ];
    return newState;
  },
  [TASK_DRAFT]: (state, { data }) => ({ ...state, draft: data }),
  [TASK_RECIPIENT_LICENSE_CHANGED]: (state, { recipientLicense }) => ({ ...state, recipientLicense }),
  [TASK_SENDER_LICENSE_CHANGED]: (state, { senderLicense }) => ({ ...state, senderLicense }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state
};
