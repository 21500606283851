import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isLoaded, isEmpty, withFirebase } from 'react-redux-firebase';

// import * as Sentry from '@sentry/browser';

import Loading from 'components/Loading';
import { enhance, withSettings } from 'utils';
import { loginPath } from 'constants/routes';
import * as roles from 'constants/roles';

import { NotificationManager, NotificationPosition } from 'components/Layout';

const withAuthorization = (condition, path = loginPath) => Component => {
  class WithAuthorization extends React.Component {
    UNSAFE_componentWillUpdate(nextProps) {
      const { auth, profile, history, firebase, settings } = nextProps || {};
      const isValidRole = [roles.DISPATCHER, roles.ADMIN].includes(profile.role);

      if (isLoaded(auth) && isLoaded(profile) && !condition(nextProps)) {
        if (
          !isEmpty(profile) &&
          !isValidRole
        ) {
          firebase.logout();
          console.log('User needs to have a valid role to access this area');
          // TODO: Manager should be able to keep message for n seconds before removing it
          NotificationManager.error(
            'User needs to have a valid role to access this area',
            null,
            NotificationPosition.TOP_LEFT,
          );
        }

        // if user is disable then logout and notify the reason
        if (profile.disabled) {
          firebase.logout();
          NotificationManager.error(
            `Your account was disabled. Please contact ${settings.supportEmail} for more information.`,
            null,
            NotificationPosition.TOP_LEFT,
          );
        }
        // const msg = `You don't have access to this page ${url}`;
        // if (process.env.NODE_ENV !== 'development') {
        // Sentry.withScope(scope => {
        // scope.setExtra('nextProps', nextProps);
        // Sentry.captureMessage(msg);
        // });
        // } else {
        // console.log(msg, nextProps);
        // }
        history.push(path);
      }
    }

    render() {
      const { auth, profile, settings } = this.props;
      let message = null;

      if (isLoaded(auth) && isLoaded(profile) && isLoaded(settings)) {
        if (!condition(this.props)) {
          return null;
        } else {
          return <Component {...this.props} />;
        }
      } else {
        message = [];
        if (!isLoaded(auth)) {
          message.push('Authenticating');
        }
        if (!isLoaded(profile)) {
          message.push('Getting Profile');
        }
        if (!isLoaded(settings)) {
          message.push('Getting Settings');
        }
        message = message.join(', ') + '...';
      }

      return <Loading {...this.props} message={message} />;
    }
  }

  const mapStateToProps = ({ appState }) => ({ appState });

  return compose(
    enhance,
    withRouter,
    withFirebase,
    withSettings,
    connect(mapStateToProps)
  )(WithAuthorization);
};

export default withAuthorization;
