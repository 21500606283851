import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import $ from 'jquery';
import { pick, forEach, isEqual, sortBy } from 'lodash';

import 'select2/dist/js/select2';
import 'select2/dist/css/select2.css';
import { authHeaders } from "utils/apiHelpers";

const requiredOnePropCheck = (props, propName, componentName) => {
  if (!props.options && !props.ajax) {
    return new Error(`'options' or 'ajax' is required by ${componentName} component.`);
  }
  if ('options' === propName && props.options) {
    PropTypes.checkPropTypes({
      options: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
          }).isRequired
        ),
        PropTypes.func
      ]).isRequired,
    },
      props,
      propName,
      componentName);
  }
  if ('ajax' === propName && props.ajax) {
    PropTypes.checkPropTypes({
      ajax: PropTypes.object,
    },
      props,
      propName,
      componentName);
  }
}

class Select extends Component {
  static propTypes = {
    id: PropTypes.string,
    creatable: PropTypes.bool,
    onCreate: PropTypes.func,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    options: requiredOnePropCheck,
    ajax: requiredOnePropCheck,
  };

  static select2Events = {
    onChange: 'change',
    onOpen: 'select2:open',
    onClose: 'select2:close',
  };

  componentDidMount() {
    this.initSelect2();
    // This is in order to make sure that selected value is passed
    if (!this.props.placeholder) {
      this.$el.trigger('change');
    }
  }

  initSelect2() {
    this.$el = $(this.el);
    const options = pick(this.props, ['placeholder', 'ajax', 'minimumInputLength', 'escapeMarkup', 'templateResult', 'templateSelection',]);

    if (options.templateResult && !options.escapeMarkup) {
      options.escapeMarkup = function (markup) { return markup; };
    }

    if (this.props.creatable) {
      options.tags = true;
    }

    if (this.props.onCreate) {
      options.createTag = this.props.onCreate;
    }

    if (this.props.readOnly) {
      options.disabled = true;
    }

    if (options.ajax) {
      authHeaders().then(headers => {
        options.ajax = { ...options.ajax, headers: headers };
        this.$el.select2(options);
      });
    }

    this.$el.select2(options);

    const events = pick(this.props, ['onChange', 'onClose']);
    forEach(events, (func, eventName) => {
      this.$el.on(Select.select2Events[eventName], func);
    });

    this.$el.on(Select.select2Events['onOpen'], (event) => {
      document.querySelector('.select2-search__field').focus();
      if (this.props.onOpen) {
        this.props.onOpen(event);
      }
    });

  }

  destroySelect2() {
    if (this.$el.select2 === undefined || this.$el.data('select2') === undefined) {
      return;
    }

    this.$el.off('change.select2');
    this.$el.select2('destroy');
    const events = ['onChange', 'onOpen', 'onClose'];
    forEach(events, (eventName) => {
      this.$el.off(Select.select2Events[eventName]);
    });
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(sortBy(this.props.options), sortBy(prevProps.options))
      || this.props.value !== prevProps.value
    ) {
      this.destroySelect2();
      this.initSelect2();
    }
  }

  componentWillUnmount() {
    this.destroySelect2();
  }

  render() {
    var { id, options, value, placeholder, readOnly } = this.props;
    if ('function' === typeof options) {
      options = options();
    }
    if (this.$el) {
      this.$el.prop('disabled', readOnly);
    }

    return (
      <Input
        name={this.props.name}
        type="select"
        innerRef={el => this.el = el}
        value={value}
        id={id}>
        {placeholder && <option></option>}
        {options && options.map((option, idx) => <option disabled={option.disabled} value={option.value} key={idx}>{option.label}</option>)}
      </Input>
    )
  }
}

export default Select;
