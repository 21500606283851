import React from 'react';
import { Route } from 'react-router-dom';
import WithAuthorization from 'components/WithAuthorization';
import { AppLayout } from 'components/Layout';
import { authCondition } from 'utils';

const AppRoute = ({ component: Component, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (
        <AppLayout {...rest} component={Component} />
      )}
    />
  );
}

export default WithAuthorization(authCondition)(AppRoute);
