export const CREATED = 'CREATED';
export const DRAFT = 'DRAFT';
export const ASSIGNED = 'ASSIGNED';
export const PROGRESS = 'PROGRESS';
export const ARRIVAL = 'ARRIVAL';
export const COMPLETED = 'COMPLETED';
export const CANCELED = 'CANCELED';

// Order Requests
export const ACCEPTED = 'ACCEPTED';
export const DENIED = 'DENIED';

export const statusList = [
    CREATED,
    ASSIGNED,
    PROGRESS,
    ARRIVAL,
    COMPLETED,
    CANCELED,
]
