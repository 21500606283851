import { api } from "config";
import qs from "qs";
import { getLine1FromAddress } from "utils";
import { getAuth } from "utils/apiHelpers";

export const geocodeAddress = address => {
  if (!address) {
    return {};
  }

  return getAuth(api.geocode + '?' + qs.stringify({ address }))
    .then(json => {
      const [address] = json.results;

      if (!address) {
        return;
      }

      const addressComponents = address.address_components;
      const location = address.geometry.location;
      const formatted = address.formatted_address;
      const googlePlaceId = address.place_id;
      const postCode = addressComponents.find((component) => component.types.indexOf('postal_code') !== -1);
      const state = addressComponents.find((component) => component.types.indexOf('administrative_area_level_1') !== -1);
      const country = addressComponents.find((component) => component.types.indexOf('country') !== -1);
      return {
        location: { lat: location.lat, lon: location.lng },
        address: {
          formatted,
          line1: getLine1FromAddress(formatted),
          state: state && state.short_name,
          country: country && country.short_name,
          postCode: (postCode && postCode.short_name) || '',
        },
        extra: { googlePlaceId },
      }
    });
}
