const SET_LOADING = 'SET_LOADING';

// actions
export const actions = {
  setLoading: (isLoading) => ({ type: SET_LOADING, isLoading }),
};

// initial state
const initialState = {
  isLoading: false,
};

// action handlers
const actionHandlers = {
  [SET_LOADING]: (state, { isLoading }) => ({ ...state, isLoading }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
};
