import moment from 'moment';
import * as collections from 'constants/collections';
import { get } from 'lodash';
import { parsedOrders } from 'utils';
import * as roles from 'constants/roles';
import * as taskStatus from 'constants/taskStatus';

export const disabledVehiclesStorage = 'disabledVehicles';
export const disabledRecipientsStorage = 'disabledRecipients';

const fromDate = moment().startOf('month').subtract(1,'months').startOf('day');

const getTasks = (ordered, data) => {
  const parsedTasks = parsedOrders({
    orders: ordered.tasks,
    users: data.users,
    destinations: data.destinations,
    recipients: ordered.recipients,
    vehicles: ordered.vehicles,
  });

  return parsedTasks.filter(task => (taskStatus.statusList.includes(task.status)));
}


const getRecipientsQuery = () => ({
  collection: collections.RECIPIENT,
  where: ['disabled', '==', false],
  orderBy: ['name', 'asc']
});

const getDisabledRecipientsQuery = () => ({
  collection: collections.RECIPIENT,
  where: ['disabled', '==', true],
  orderBy: ['name', 'asc'],
  storeAs: disabledRecipientsStorage
});

const getTasksQuery = () => ({
  collection: collections.TASK,
  where: ['estimatedTime', '>', fromDate.unix()],
  orderBy: ['estimatedTime', 'asc'],
});

const getVehiclesQuery = () => ({
  collection: collections.VEHICLE,
  where: ['disabled', '==', false],
});

const getDisabledVehiclesQuery = () => ({
  collection: collections.VEHICLE,
  where: ['disabled', '==', true],
  storeAs: disabledVehiclesStorage
});

const getTerminalsQuery = () => ({
  collection: collections.TERMINAL,
  where: ['disabled', '==', false],
  orderBy: ['name', 'asc'],
});

const getBrandsQuery = () => ({
  collection: collections.BRAND,
  where: ['disabled', '==', false],
  orderBy: ['name', 'asc'],
})

const getDestinationsQuery = () => ({
  collection: collections.DESTINATION,
})

const getCashOrdersQuery = () => ({
  collection: collections.CASH_ORDERS,
})

const getObjectValue = (object, key, defaultValue = []) => get(object, key, defaultValue);

export const routesConfig = {
  monitoring: {
    getCollections: () => ([
      getTasksQuery(),
      getDestinationsQuery(),
      getRecipientsQuery(),
      getDisabledRecipientsQuery(),
      getVehiclesQuery(),
      getDisabledVehiclesQuery(),
    ]),

    getComponentProps: ({ ordered, data }) => ({
      tasks: getTasks(ordered, data),
      vehicles: getObjectValue(ordered, "vehicles"),
      recipients: getObjectValue(ordered, "recipients")
    })
  },

  orderForm: {
    getCollections: () => ([
      getTasksQuery(),
      getDestinationsQuery(),
      getRecipientsQuery(),
      getVehiclesQuery(),
      getTerminalsQuery(),
      getBrandsQuery(),
    ]),

    getComponentProps: ({ ordered, currentUser, task }) => ({
      vehicles: getObjectValue(ordered, "vehicles"),
      recipients: getObjectValue(ordered, "recipients"),
      destinations: getObjectValue(ordered, "destinations"),
      drivers: ordered.users?.filter(item => item.role === roles.DRIVER),
      terminals: getObjectValue(ordered, "terminals"),
      brands: getObjectValue(ordered, "brands"),
      currentUser,
      task
    }),
  },

  orderRequests: {
    getCollections: () => ([
      getDestinationsQuery(),
    ]),

    getComponentProps: () => ({})
  },

  orderList: {
    getCollections: () => ([
      getRecipientsQuery(),
    ]),

    getComponentProps: ({ ordered }) => ({
      recipients: getObjectValue(ordered, "recipients"),
      users: getObjectValue(ordered, "users"),
      drivers: getObjectValue(ordered, "users").filter(item => item.role === roles.DRIVER),
    }),

  },

  orderDrafts: {
    getCollections: () => ([
      getRecipientsQuery(),
    ]),

    getComponentProps: () => ({})
  },

  cashOrders: {
    getCollections: () => ([
      getRecipientsQuery(),
    ]),

    getComponentProps: () => ({})
  },

  editCashOrderPath: {
    getCollections: () => ([
      getRecipientsQuery(),
      getCashOrdersQuery(),
    ]),

    getComponentProps: () => ({})
  }

}
