import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProgressBar extends Component {
  static propTypes = { 
    visible: PropTypes.bool,
  };

  render() {

    const { visible } = this.props;
    
    if (!visible) {
      return null;
    }

    return (
      <div className="progress boltbox-progress">
        <div className="progress-bar-indeterminate"></div>
      </div>
    )
  }
}

export default ProgressBar;
