import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import { InputGroupAddon, Input, Button, FormGroup, Table, CustomInput } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
import DatePicker from 'react-datepicker';
import { NotificationManager, NotificationPosition } from 'components/Layout';
import { Destination } from 'components/Destination';
import Select from 'components/Select';
import WithLoader from 'components/WithLoader';
import * as destinations from 'constants/destinations';
import * as collections from 'constants/collections';
import timeZones from 'constants/timeZones';
import days from 'constants/days';
import { actions as generalSettingsActions } from 'reducers/settings';
import { actions as fetchingActions } from 'reducers/fetching';
import { now } from 'utils';
import { pick } from 'lodash';

import 'react-datepicker/dist/react-datepicker.css';

class GeneralSettings extends Component {

  static defaultProps = {
    dayFormat: 'January 1, 1970'
  };

  constructor(props) {
    super(props);
    this.showSettings = this.showSettings.bind(this);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      renderData: false,
      hours: {
        open: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
        },
        close: {
          monday: '',
          tuesday: '',
          wednesday: '',
          thursday: '',
          friday: '',
          saturday: '',
          sunday: '',
        }
      }
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleChange(event) {
    event.persist();

    this.setState(prevState => ({ hours: { open: { ...prevState.hours.open, [event.target.id]: event.target.checked ? new Date(this.props.dayFormat + ' 08:00 am').getTime() : '' }, close: { ...prevState.hours.close } } }))
    this.setState(prevState => ({ hours: { close: { ...prevState.hours.close, [event.target.id]: event.target.checked ? new Date(this.props.dayFormat + ' 11:59 pm').getTime() : '' }, open: { ...prevState.hours.open } } }))
  }


  showSettings() {
    const {
      onSettingsNew,
      onSettingsEdit,
      onSettingsArrivingNotificationIntervalChanged,
      settings,
    } = this.props;

    if (settings.arrivingNotificationInterval === undefined) {
      onSettingsArrivingNotificationIntervalChanged(30);
    }

    this.setState({
      renderData: true
    });

    if (!!settings) {

      onSettingsEdit(settings);

      const hoursArray = settings.hours;

      if (hoursArray !== undefined && settings.hourStatus === 'open_selected_hours') {
        this.setState(({
          hours: hoursArray
        }));
      }


    } else {
      onSettingsNew();
    }
  }

  convertMillisToTime(millis) {
    let delim = " ";
    let hours = Math.floor(millis / (1000 * 60 * 60) % 60);
    let minutes = Math.floor(millis / (1000 * 60) % 60);
    let seconds = Math.floor(millis / 1000 % 60);
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours + 'h' + delim + minutes + 'm' + delim + seconds + 's';
  }

  getDefaultDate(event) {
    if (event) {
      var hour = event.toLocaleTimeString();
      var time = new Date(this.props.dayFormat + ' ' + hour);
      // check
      return time.getTime();
    }

    return '';
  }

  async saveGeneralSettings() {
    const { destination, setting: settings, firestore } = this.props;

    if (
      destination.address.state === '' ||
      destination.address.country === '' ||
      destination.address.formatted === '' ||
      destination.address.line1 === ''
    ) {
      NotificationManager.error('A valid address is required!', null, NotificationPosition.TOP_LEFT);
      return;
    }

    const settingsData = pick(settings, [
      'companyName',
      'companyEmail',
      'supportEmail',
      'taxPercentage',
      'fuelSurcharge',
      'phoneNumber',
      'emailReport',
      'hourStatus',
      'timeZone',
      'arrivingNotificationInterval',
    ]);
    let hours = {};
    if (settings.hourStatus === 'open_selected_hours') {
      hours = this.state.hours;
    }

    let data = {
      ...settingsData,
      companyAddress: {
        address: { ...destination.address },
        location: { ...destination.location },
        extra: { ...destination.extra },
        name: destination.name
      },
      hours,
      ...(!settings.id ? { createdAt: now() } : {}),
      modifiedAt: now(),
    }

    this.props.setLoading(true);
    if (settings.id) {
      await firestore
        .collection(collections.SETTING)
        .doc(settings.id)
        .update(data)
        .then(result => {
          NotificationManager.success('Successfully updated!', null, NotificationPosition.TOP_LEFT);
        });
    } else {
      await firestore
        .add(collections.SETTING, data)
        .then(() => {
          NotificationManager.success('Successfully created!', null, NotificationPosition.TOP_LEFT);
        })
    }
    this.props.setLoading(false);
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    className: PropTypes.string
  };

  getSettingsForm() {
    const {
      onSettingsNameChanged,
      onSettingsEmailChanged,
      onSettingsSupportEmailChanged,
      onSettingsEmailReportChanged,
      onSettingsTaxPercentChanged,
      onSettingsFuelSurchargeChanged,
      onSettingsTimeZoneChanged,
      onSettingsPhoneNumberChanged,
      onSettingsArrivingNotificationIntervalChanged,
      setting: settings,
    } = this.props;

    return (
      <div>
        <form
          className='p-t-15'
          onSubmit={e => {
            e.preventDefault();
            this.saveGeneralSettings();
          }}
        >
          <FormGroup className={'form-group-default'}>
            <label>Company Name</label>
            <div className='controls'>
              <input
                type='text'
                name='companyName'
                placeholder='Company Name'
                className='form-control'
                required
                value={settings.companyName || ''}
                onChange={event => onSettingsNameChanged(event.target.value)}
              />
            </div>
          </FormGroup>

          <FormGroup className={'form-group-default'}>
            <label>Company Email</label>
            <div className='controls'>
              <input
                type='text'
                required
                name='companyEmail'
                placeholder='Company Email'
                className='form-control'
                value={settings.companyEmail || ''}
                onChange={event => onSettingsEmailChanged(event.target.value)}
              />
            </div>
          </FormGroup>

          <FormGroup className={'form-group-default'}>
            <label>Support Email</label>
            <div className='controls'>
              <input
                type='text'
                required
                name='supportEmail'
                placeholder='Support Email'
                className='form-control'
                value={settings.supportEmail || ''}
                onChange={event => onSettingsSupportEmailChanged(event.target.value)}
              />
            </div>
          </FormGroup>

          <FormGroup className={'form-group form-group-default input-group'}>
            <div className={'form-input-group'}>
              <label>Tax Percentage</label>
              <Input
                placeholder={`Enter tax percentage`}
                min={0}
                max={100}
                type='number'
                step='0.01'
                className='form-control'
                value={settings.taxPercentage || ''}
                onChange={event =>
                  onSettingsTaxPercentChanged(event.target.value)
                }
              />
            </div>
            <InputGroupAddon addonType='append'>.00</InputGroupAddon>
          </FormGroup>

          <FormGroup className={'form-group form-group-default input-group'}>
            <div className={'form-input-group'}>
              <label>Fuel Surcharge</label>
              <Input
                placeholder={`Enter fuel surcharge`}
                min={0}
                max={100}
                type='number'
                step='0.01'
                className='form-control'
                value={settings.fuelSurcharge || ''}
                onChange={event =>
                  onSettingsFuelSurchargeChanged(event.target.value)
                }
              />
            </div>
            <InputGroupAddon addonType='append'>.00</InputGroupAddon>
          </FormGroup>

          <FormGroup className={'form-group-default'}>
            <label>Phone Number</label>
            <div className='controls'>
              <PhoneInput
                className='form-control'
                placeholder='Phone Number'
                defaultCountry='US'
                type='text'
                required
                name='phoneNumber'
                value={settings.phoneNumber || ''}
                onChange={event => onSettingsPhoneNumberChanged(event)}
              />
            </div>
          </FormGroup>
          <Destination
            as={destinations.COMPANY_ADDRESS}
            defaultData={settings.companyAddress}
            readOnly
          />
          <FormGroup className={'form-group-default'}>
            <label>Time Zone</label>
            <div className='controls'>
              <Select
                name='timezone'
                id='timezone'
                value={settings.timeZone}
                options={
                  timeZones.map(value => ({ label: value, value })) || []
                }
                onChange={event =>
                  onSettingsTimeZoneChanged(event.target.value)
                }
              />
            </div>
          </FormGroup>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group form-group-default'>
                <label>Email Reports</label>
                <div className='controls'>
                  <CustomInput
                    type='radio'
                    id='weekly_mail'
                    name='customRadio'
                    label='Weekly Mail'
                    checked={settings.emailReport === 'weekly_mail'}
                    onChange={event =>
                      onSettingsEmailReportChanged(event.target.id)
                    }
                  />
                  <CustomInput
                    type='radio'
                    id='daily_mail'
                    name='customRadio'
                    label='Daily Mail'
                    checked={settings.emailReport === 'daily_mail'}
                    onChange={event =>
                      onSettingsEmailReportChanged(event.target.id)
                    }
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='form-group form-group-default'>
                <label>Arriving Notification Interval</label>
                <div className='controls'>
                  <Input
                    type='select'
                    id='arrivingNotificationInterval'
                    name='arrivingNotificationInterval'
                    onChange={e =>
                      onSettingsArrivingNotificationIntervalChanged(
                        e.target.value,
                      )
                    }
                    value={settings.arrivingNotificationInterval}
                  >
                    {['15', '30', '45', '60'].map(item => (
                      <option key={item} value={item}>
                        {`${item} min`}
                      </option>
                    ))}
                  </Input>
                </div>
              </div>
            </div>
          </div>
          {this.getOpeningHoursForm()}
          <br />
          <Button color='primary'>Done</Button>
        </form>
      </div>
    );
  }

  stringTimeToDate = time => {
    return new Date(this.props.dayFormat + ` ${time}`).getTime()
  }

  onHoursChange = (type, value, day) => {
    const oppositeType = type === 'open' ? 'close' : 'open';
    const defaultOppositeHour = type === 'open' ? '11:59 pm' : '08:00 am';

    this.setState(prevState => ({
      hours: {
        [type]: {
          ...prevState.hours[type],
          [day]: this.getDefaultDate(value)
        },
        [oppositeType]: {
          ...prevState.hours[oppositeType],
          [day]: !value ? '' : (prevState.hours.close[day] === '' ? this.stringTimeToDate(defaultOppositeHour) : prevState.hours[oppositeType][day])
        }
      }
    }))
  }

  getOpeningHoursForm() {
    const { onSettingsHourStatusChanged, setting: settings } = this.props;
    return (
      <div>
        <h5> Hours </h5>
        <div className="controls">
          <CustomInput
            type="radio"
            id="open_selected_hours"
            name="hoursRadio"
            label="Open on selected hours"
            checked={settings.hourStatus === "open_selected_hours"}
            onChange={event =>
              onSettingsHourStatusChanged(event.target.id)
            }
          />
          <CustomInput
            type="radio"
            id="no_hours_selected"
            name="hoursRadio"
            checked={settings.hourStatus === "no_hours_selected"}
            onChange={event =>
              onSettingsHourStatusChanged(event.target.id)
            }
            label="No hours available"
          />
        </div>
        {settings.hourStatus === "open_selected_hours" ?
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Day</th>
                <th>Open</th>
                <th>Close</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {days.map((item) => (
                <tr key={item.value} >
                  <th scope="row" >
                    <CustomInput
                      type="checkbox"
                      checked={this.state.hours.open[item.value] && this.state.hours.close[item.value]}
                      onChange={this.handleChange.bind(this)}
                      id={item.value} />
                  </th>
                  <td>
                    <button
                      className={"btn btn-link"}
                      href="#">
                      {item.label}
                    </button>
                  </td>
                  <td>
                    <DatePicker
                      selected={this.state.hours.open[item.value]}
                      onChange={(value) => this.onHoursChange('open', value, item.value)}
                      showTimeSelect
                      selectsStart
                      showTimeSelectOnly
                      timeIntervals={15}
                      id={`open_${item.value}`}
                      //dateFormat="h:mm aa"
                      //readOnly={!this.state.hours.open[item.value] && !this.state.hours.close[item.value]}
                      dateFormat="h:mm aa"
                      timeCaption="Time"
                    />
                  </td>
                  <td>
                    <DatePicker
                      selected={this.state.hours.close[item.value]}
                      onChange={(value) => this.onHoursChange('close', value, item.value)}
                      showTimeSelect
                      showTimeSelectOnly
                      id={`close_${item.value}`}
                      timeIntervals={15}
                      //readOnly={!this.state.hours.open[item.value] && !this.state.hours.close[item.value]}
                      dateFormat="h:mm aa"
                      timeCaption="Time"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> : null}
      </div>
    );
  }

  componentDidMount() {
    this.state.renderData === false && this.showSettings();
  }

  render() {
    return (
      <div>
        {this.getSettingsForm()}
      </div>
    );
  }
}

const mapStateToProps = ({
  destination,
  firestore: {
    ordered: { settings }
  },
  setting
}) => ({
  destination: destination[destinations.COMPANY_ADDRESS],
  settings: settings && settings.length && settings[0],
  setting
});

const mapDispatchToProps = {
  ...generalSettingsActions,
  ...fetchingActions
};

const FirestoreConnected = compose(
  withFirebase,
  withFirestore,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(GeneralSettings);

export default WithLoader(FirestoreConnected);
