const NotificationType = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};

const NotificationPosition = {
  TOP: 'top',                   // for Bar style
  BOTTOM: 'bottom',             // for Bar style
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
};

export {
  NotificationType,
  NotificationPosition
};
