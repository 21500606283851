const TASK_DETAIL_ITEM_CHANGED = 'TASK_DETAIL_ITEM_CHANGED';
const TASK_DETAIL_DESCRIPTION_CHANGED = 'TASK_DETAIL_DESCRIPTION_CHANGED';
const TASK_DETAIL_FORM_CLEAR = 'TASK_DETAIL_FORM_CLEAR';
const TASK_DETAIL_QUANTITY_CHANGED = 'TASK_DETAIL_QUANTITY_CHANGED';
const TASK_DETAIL_PRICE_CHANGED = 'TASK_DETAIL_PRICE_CHANGED';

// actions
export const actions = {
  onItemChanged: (item) => ({ type: TASK_DETAIL_ITEM_CHANGED, item }),
  onDescriptionChanged: (description) => ({ type: TASK_DETAIL_DESCRIPTION_CHANGED, description }),
  onQuantityChanged: (quantity) => ({ type: TASK_DETAIL_QUANTITY_CHANGED, quantity: parseInt(quantity) }),
  onPriceChanged: (price) => ({ type: TASK_DETAIL_PRICE_CHANGED, price: parseFloat(price) }),
  taskDetailItemClear: () => ({ type: TASK_DETAIL_FORM_CLEAR }),
};

// initial state
const initialState = {
  item: '',
  description: '',
  quantity: '',
  price: '',
};

// action handlers
const actionHandlers = {
  [TASK_DETAIL_ITEM_CHANGED]: (state, { item }) => ({ ...state, item }),
  [TASK_DETAIL_DESCRIPTION_CHANGED]: (state, { description }) => ({ ...state, description }),
  [TASK_DETAIL_QUANTITY_CHANGED]: (state, { quantity }) => ({ ...state, quantity }),
  [TASK_DETAIL_PRICE_CHANGED]: (state, { price }) => ({ ...state, price }),
  [TASK_DETAIL_FORM_CLEAR]: () => ({...initialState}),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state
};
