import React from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading';

const withLoader = (Component) => {
  class WithLoader extends React.Component {
    render() {
      const { isLoading } = this.props;
      return (
        <div className={`overlay-loader ${isLoading ? 'active' : ''}`}>
          {isLoading && <div className="loader"><Loading /></div>}
          <Component {...this.props} />
        </div>
      );
    }
  }

  const mapStateToProps = ({ fetching: { isLoading } }) => ({ isLoading });
  return connect(mapStateToProps)(WithLoader)
};

export default withLoader;
